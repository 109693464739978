import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import {
  CheckCircleRounded as CheckCircleRoundedIcon,
  ErrorRounded as ErrorRoundedIcon
} from "@mui/icons-material";
import { Box, InputLabel, Link, Stack, Tooltip } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";

import TextField from "@components/TextField";
import Typography from "@components/Typography";

import { auth } from "@utils/firebase";
import translate, { intl } from "@utils/translate";

const CandidateAccountInformation = () => {
  const [user] = useAuthState(auth);
  const { pathname } = useLocation();

  const hasEmailAndPasswordProvider = user?.providerData.some(
    (provider) => provider.providerId === "password"
  );

  const methods = useForm({
    defaultValues: {
      email: user?.email,
      password: hasEmailAndPasswordProvider ? "••••••••" : ""
    }
  });

  const { control } = methods;

  return (
    <Box noValidate component="form" mt={3}>
      <InputLabel>{intl.get("t_account_setting_form_email")}</InputLabel>
      <TextField name="email" control={control} disabled />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        mb={2.25}>
        {user?.emailVerified ? (
          <Stack
            direction="row"
            color="success.main"
            alignItems="center"
            spacing={1}>
            <CheckCircleRoundedIcon />
            <Typography variant="subtitle4">
              {intl.get("t_account_setting_email_verified")}
            </Typography>
          </Stack>
        ) : (
          <Stack
            direction="row"
            color="warning.main"
            alignItems="center"
            spacing={1}>
            <ErrorRoundedIcon />
            <Typography variant="subtitle4">
              {intl.get("t_account_setting_email_not_verified")}
            </Typography>
          </Stack>
        )}

        <Typography variant="subtitle4" color="primary.main">
          <Link
            underline="none"
            href="https://www.tokhimo.com/tokhimo-jobs-faq"
            target="_blank">
            {intl.get("t_account_setting_change_email")}
          </Link>
        </Typography>
      </Stack>

      <InputLabel disabled={!hasEmailAndPasswordProvider}>
        {intl.get("t_account_setting_form_password")}
      </InputLabel>
      <TextField name="password" control={control} disabled />
      <Stack direction="row-reverse">
        {hasEmailAndPasswordProvider ? (
          <Typography variant="subtitle4">
            <Link
              underline="none"
              href={`/${translate.getCurrentLocale()}/change-password?continueUrl=${pathname}`}
              target="_blank"
              color="primary.main">
              {intl.get("t_account_setting_change_password")}
            </Link>
          </Typography>
        ) : (
          <Tooltip
            arrow
            placement="bottom"
            title={
              <Typography variant="body2">
                {intl.get("t_general_login_sso_not_change_password_message")}
              </Typography>
            }>
            {/* span tag is required, otherwise it will not show the tooltip */}
            <span>
              <Typography variant="subtitle4" sx={{ cursor: "pointer" }}>
                <Link
                  underline="none"
                  href={`/${translate.getCurrentLocale()}/change-password?continueUrl=${pathname}`}
                  target="_blank"
                  sx={{
                    pointerEvents: "none"
                  }}
                  color="text.disabled">
                  {intl.get("t_account_setting_change_password")}
                </Link>
              </Typography>
            </span>
          </Tooltip>
        )}
      </Stack>
    </Box>
  );
};

export default CandidateAccountInformation;

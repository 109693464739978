import { Link } from "react-router-dom";

import { CheckCircle as CheckCircleIcon } from "@mui/icons-material";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link as MuiLink,
  Stack,
  styled,
  Typography
} from "@mui/material";

import Button from "@components/Button";
import SignInSignUpDialog from "@components/Header/SignInSignUpDialog";

import useUserProfile from "@hooks/database/useUserProfile";

import {
  SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY,
  USER_TYPE
} from "@utils/config";
import { colorPalette } from "@utils/theme";
import { intl } from "@utils/translate";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: 0,
  marginBottom: 12,
  color: theme.palette.primary.contrastText
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 32,
  color: theme.palette.primary.contrastText
}));

const PricingCard = () => {
  const userProfile = useUserProfile();
  const userType = userProfile?.value?.user_type;

  const GetStartedForFreeBtn = () => {
    if (userType) {
      if (userType === USER_TYPE.NO_TYPE) {
        // No action. Just show button
        return (
          <Button
            size="large"
            fullWidth={true}
            data-testid="employer_homepage_no_type_user_section_start_for_free_button">
            {intl.get("t_homepage_pricing_card_button")}
          </Button>
        );
      } else if (userType === USER_TYPE.COMPANY) {
        return (
          <MuiLink component={Link} to="dashboard" underline="none">
            <Button
              size="large"
              fullWidth={true}
              data-testid="employer_homepage_company_type_section_start_for_free_button">
              {intl.get("t_homepage_pricing_card_button")}
            </Button>
          </MuiLink>
        );
      } else {
        return (
          <MuiLink
            component={Link}
            to="https://www.tokhimo.com/jobs-contact"
            underline="none"
            target="_blank">
            <Button
              size="large"
              fullWidth={true}
              data-testid="employer_homepage_benefit_section_start_for_free_button">
              {intl.get("t_homepage_pricing_card_button")}
            </Button>
          </MuiLink>
        );
      }
    } else {
      return (
        <SignInSignUpDialog
          initiator={
            <Button
              size="large"
              fullWidth={true}
              data-testid="employer_homepage_section_start_for_free_button">
              {intl.get("t_homepage_pricing_card_button")}
            </Button>
          }
          initiatorType="start-for-free"
          activeContent={SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY.SIGN_UP}
        />
      );
    }
  };

  return (
    // FIXME: will recheck responsive typography is updated
    <Stack
      spacing={2}
      bgcolor={colorPalette.lightBlue.background[30]}
      borderRadius={1}
      p={3}>
      <Typography variant="h3" color="primary.contrastText">
        {intl.get("t_homepage_tokhimo_plans")}
      </Typography>
      <Stack direction="row" alignItems="flex-end">
        <Typography variant="h2" color="primary.contrastText">
          {intl.get("t_homepage_pricing_card_free")}
        </Typography>
        <Typography variant="subtitle3" color={colorPalette.white[80]} mb={1}>
          &nbsp;{intl.get("t_homepage_per_month")}
        </Typography>
      </Stack>
      <List disablePadding>
        <StyledListItem>
          <StyledListItemIcon>
            <CheckCircleIcon />
          </StyledListItemIcon>
          <ListItemText primary={intl.get("t_homepage_post_unlimited_jobs")} />
        </StyledListItem>
        <StyledListItem>
          <StyledListItemIcon>
            <CheckCircleIcon />
          </StyledListItemIcon>
          <ListItemText
            primary={intl.get("t_homepage_send_unlimited_invitation")}
          />
        </StyledListItem>
        <StyledListItem>
          <StyledListItemIcon>
            <CheckCircleIcon />
          </StyledListItemIcon>
          <ListItemText primary={intl.get("t_homepage_pay_per_full_resume")} />
        </StyledListItem>
        <StyledListItem>
          <StyledListItemIcon>
            <CheckCircleIcon />
          </StyledListItemIcon>
          <ListItemText
            primary={intl.get("t_homepage_pricing_card_refund_policy")}
          />
        </StyledListItem>
      </List>
      <Typography
        variant="subtitle3"
        component="p"
        color={colorPalette.white[80]}
        paddingBottom={2}>
        {intl.get("t_homepage_pricing_card_content")}
      </Typography>
      <GetStartedForFreeBtn />
    </Stack>
  );
};

export default PricingCard;

import { createBrowserRouter, Navigate } from "react-router-dom";

import AppliedJobs from "@pages/AppliedJobs";
import BookmarkedJobs from "@pages/BookmarkedJobs";
import CandidateConversations from "@pages/CandidateConversations";
import CandidateNotifications from "@pages/CandidateNotifications";
import ChangePassword from "@pages/ChangePassword";
import EmployerConversations from "@pages/EmployerConversations";
import EmployerNotifications from "@pages/EmployerNotifications";
import EmployersApp from "@pages/EmployersApp";
import EmployersDashboard from "@pages/EmployersDashboard";
import EmployersHome from "@pages/EmployersHome";
import EmployersPostJob from "@pages/EmployersPostJob";
import AdditionalInformation from "@pages/EmployersPostJob/AdditionalInformation";
import CompanyInformation from "@pages/EmployersPostJob/CompanyInformation";
import Confirmation from "@pages/EmployersPostJob/Confirmation";
import HiringProcess from "@pages/EmployersPostJob/HiringProcess";
import JobInformation from "@pages/EmployersPostJob/JobInformation";
import JobOverview from "@pages/EmployersPostJob/JobOverview";
import LanguageRequirement from "@pages/EmployersPostJob/LanguageRequirement";
import SkillRequirement from "@pages/EmployersPostJob/SkillRequirement";
import Success from "@pages/EmployersPostJob/Success";
import EmployersSavedCandidates from "@pages/EmployersSavedCandidates";
import EmployersSearch from "@pages/EmployersSearch";
import EmployersSettings from "@pages/EmployersSettings";
import EmployersAccountSettings from "@pages/EmployersSettings/AccountSettings";
import CompanySettings from "@pages/EmployersSettings/CompanySettings";
import ManageTeam from "@pages/EmployersSettings/ManageTeam";
import InviteCompanyManagerForm from "@pages/EmployersSettings/ManageTeam/InviteCompanyManagerForm";
import TeamList from "@pages/EmployersSettings/ManageTeam/TeamList";
import Plans from "@pages/EmployersSettings/Plans";
import ResumePurchases from "@pages/EmployersSettings/ResumePurchases";
import EmployerTNC from "@pages/EmployerTNC";
import EnglishResume from "@pages/EnglishResume";
import EnglishResumeEditBasicInfo from "@pages/EnglishResumeEditBasicInfo";
import EnglishResumeEditCertification from "@pages/EnglishResumeEditCertification";
import EnglishResumeEditEducation from "@pages/EnglishResumeEditEducation";
import EnglishResumeEditJobExperienceDetails from "@pages/EnglishResumeEditJobExperienceDetails";
import EnglishResumeEditJobExperienceOverview from "@pages/EnglishResumeEditJobExperienceOverview";
import EnglishResumeEditLanguage from "@pages/EnglishResumeEditLanguage";
import EnglishResumeEditSkills from "@pages/EnglishResumeEditSkills";
import FAQ from "@pages/FAQ";
import ForgotPassword from "@pages/ForgotPassword";
import Home from "@pages/Home";
import InvitedJobs from "@pages/InvitedJobs";
import JapaneseResume from "@pages/JapaneseResume";
import JapaneseResumeEditBasicInfo from "@pages/JapaneseResumeEditBasicInfo";
import JapaneseResumeEditCertification from "@pages/JapaneseResumeEditCertification";
import JapaneseResumeEditEducation from "@pages/JapaneseResumeEditEducation";
import JapaneseResumeEditJobExperienceDetails from "@pages/JapaneseResumeEditJobExperienceDetails";
import JapaneseResumeEditJobExperienceOverview from "@pages/JapaneseResumeEditJobExperienceOverview";
import JapaneseResumeEditLanguage from "@pages/JapaneseResumeEditLanguage";
import JapaneseResumeEditSkills from "@pages/JapaneseResumeEditSkills";
import JapaneseRirekisho from "@pages/JapaneseRirekisho";
import Jobs from "@pages/Jobs";
import NotFound from "@pages/NotFound";
import PrivacyPolicy from "@pages/PrivacyPolicy";
import ProfileEdit from "@pages/ProfileEdit";
import ProfileSummary from "@pages/ProfileSummary";
import Resume from "@pages/Resume";
import Search from "@pages/Search";
import UserAccountSettings from "@pages/UserAccountSettings";
import UserTNC from "@pages/UserTNC";

import EnvironmentSpecific from "@utils/components/EnvironmentSpecific";
import RequireAuth from "@utils/components/RequireAuth";
import Translate from "@utils/components/Translate";
import { ENVIRONMENT, LOCALE, USER_TYPE } from "@utils/config";

import App from "@src/App";

// FIXME: Update the router navigation paths to use constant.

const appRoutes = [
  {
    path: "",
    element: <Home />
  },
  {
    path: "forgot-password/:is_updated?",
    element: <ForgotPassword />
  },
  {
    path: "change-password",
    element: <ChangePassword />
  },
  {
    path: "search/:job_id?",
    element: <Search />,
    shouldRevalidate: () => false
  },
  {
    path: "jobs",
    children: [
      {
        path: "",
        element: <Search />,
        shouldRevalidate: () => false
      },
      {
        path: ":job_id",
        element: <Search searchSameCompany />,
        shouldRevalidate: () => false
      }
    ]
  },
  {
    path: "user/:user_id",
    element: (
      <RequireAuth accessRestriction={USER_TYPE.INDIVIDUAL}>
        <ProfileSummary />
      </RequireAuth>
    )
  },
  {
    path: "conversations/:conversation_id?",
    element: (
      <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
        <RequireAuth accessRestriction={USER_TYPE.INDIVIDUAL}>
          <CandidateConversations />
        </RequireAuth>
      </EnvironmentSpecific>
    )
  },
  {
    path: "notifications",
    element: (
      <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
        <RequireAuth accessRestriction={USER_TYPE.INDIVIDUAL}>
          <CandidateNotifications />
        </RequireAuth>
      </EnvironmentSpecific>
    )
  },
  {
    path: "profile",
    element: <RequireAuth accessRestriction={USER_TYPE.INDIVIDUAL} />,
    children: [
      {
        path: "",
        element: <ProfileSummary />
      },
      {
        path: "edit/:profile_section?",
        element: <ProfileEdit />
      },
      {
        path: "resume",
        element: <Resume />,
        children: [
          {
            path: "",
            element: <Navigate to="en-cv" replace />
          },
          {
            path: "en-cv",
            children: [
              {
                path: "",
                element: <EnglishResume />
              },
              {
                path: "edit",
                children: [
                  {
                    path: "",
                    element: <Navigate to="../../en-cv" replace />
                  },
                  {
                    path: "basic-info/",
                    element: <EnglishResumeEditBasicInfo />
                  },
                  {
                    path: "job-experience-overview/:item_id?",
                    element: <EnglishResumeEditJobExperienceOverview />
                  },
                  {
                    path: "job-experience-details/:item_id?",
                    element: <EnglishResumeEditJobExperienceDetails />
                  },
                  {
                    path: "skills",
                    element: <EnglishResumeEditSkills />
                  },
                  {
                    path: "language/:item_id?",
                    element: <EnglishResumeEditLanguage />
                  },
                  {
                    path: "education/:item_id?",
                    element: <EnglishResumeEditEducation />
                  },
                  {
                    path: "certification/:item_id?",
                    element: <EnglishResumeEditCertification />
                  }
                ]
              }
            ]
          },
          {
            path: "ja-cv",
            children: [
              {
                path: "",
                element: <JapaneseResume />
              },
              {
                path: "edit",
                children: [
                  {
                    path: "",
                    element: <Navigate to="../../ja-cv" replace />
                  },
                  {
                    path: "basic-info",
                    element: <JapaneseResumeEditBasicInfo />
                  },
                  {
                    path: "job-experience-overview/:item_id?",
                    element: <JapaneseResumeEditJobExperienceOverview />
                  },
                  {
                    path: "job-experience-details/:item_id?",
                    element: <JapaneseResumeEditJobExperienceDetails />
                  },
                  {
                    path: "skills",
                    element: <JapaneseResumeEditSkills />
                  },
                  {
                    path: "language/:item_id?",
                    element: <JapaneseResumeEditLanguage />
                  },
                  {
                    path: "education/:item_id?",
                    element: <JapaneseResumeEditEducation />
                  },
                  {
                    path: "certification/:item_id?",
                    element: <JapaneseResumeEditCertification />
                  }
                ]
              }
            ]
          },
          {
            path: "ja-rirekisho",
            element: <JapaneseRirekisho />
          }
        ]
      },
      {
        path: "jobs",
        element: <Jobs />,
        children: [
          {
            path: "",
            element: <Navigate to="bookmarked" replace />
          },
          {
            path: "bookmarked",
            element: <BookmarkedJobs />
          },
          {
            path: "applied",
            element: <AppliedJobs />
          },
          {
            path: "invited",
            element: <InvitedJobs />
          }
        ]
      },
      {
        path: "settings",
        element: <UserAccountSettings />
      }
    ]
  },
  {
    path: "not-found",
    element: <NotFound />
  },
  {
    path: "404",
    element: <NotFound />
  },
  {
    path: "frequently-asked-questions",
    element: <FAQ />
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicy />
  },
  {
    path: "user-terms-and-conditions",
    element: <UserTNC />
  },
  {
    path: "employer-terms-and-conditions",
    element: <EmployerTNC />
  },
  {
    path: "employers",
    element: <EmployersApp />,
    children: [
      {
        path: "",
        element: <EmployersHome />
      },
      {
        path: "dashboard",
        element: <EmployersDashboard />
      },
      {
        path: "jobs/:job_id?",
        element: <EmployersPostJob />,
        children: [
          {
            path: "company-information",
            element: <CompanyInformation />
          },
          {
            path: "job-information",
            element: <JobInformation />
          },
          {
            path: "skill-requirement",
            element: <SkillRequirement />
          },
          {
            path: "job-overview",
            element: <JobOverview />
          },
          {
            path: "language-requirement",
            element: <LanguageRequirement />
          },
          {
            path: "additional-information",
            element: <AdditionalInformation />
          },
          {
            path: "hiring-process",
            element: <HiringProcess />
          },
          {
            path: "confirmation",
            element: <Confirmation />
          },
          {
            path: "success",
            element: <Success />
          }
        ]
      },
      {
        path: "search/:candidate_id?",
        element: <EmployersSearch />
      },
      {
        path: "saved-candidates",
        element: <EmployersSavedCandidates />
      },
      {
        path: "settings",
        element: <EmployersSettings />,
        children: [
          {
            path: "",
            element: <Navigate to="account-settings" replace />
          },
          {
            path: "account-settings",
            element: <EmployersAccountSettings />
          },
          {
            path: "company-settings",
            element: <CompanySettings />
          },
          {
            path: "resume-purchases",
            element: <ResumePurchases />
          },
          {
            path: "manage-team",
            element: <ManageTeam />,
            children: [
              {
                path: "",
                element: <TeamList />
              },
              {
                path: "invite-people",
                element: <InviteCompanyManagerForm />
              }
            ]
          },
          {
            path: "plan",
            element: (
              <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
                <Plans />
              </EnvironmentSpecific>
            )
          }
        ]
      },
      {
        path: "conversations/:conversation_id?",
        element: (
          <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
            <EmployerConversations />
          </EnvironmentSpecific>
        )
      },
      {
        path: "notifications",
        element: (
          <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
            <EmployerNotifications />
          </EnvironmentSpecific>
        )
      }
    ]
  }
];

export const routes = [
  {
    path: "/",
    element: <App />,
    errorElement: <Navigate to="not-found" replace />,
    children: [
      {
        path: "en-US",
        element: <Translate locale={LOCALE.EN} />,
        children: [...appRoutes]
      },
      {
        path: "ja-JP",
        element: <Translate locale={LOCALE.JA} />,
        children: [...appRoutes]
      },
      ...appRoutes
    ]
  }
];

export default createBrowserRouter(routes);

import { useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  BorderColor as BorderColorIcon,
  Close as CloseIcon,
  Forum as ForumIcon,
  LocationOn as LocationOnIcon,
  Psychology as PsychologyIcon,
  RecordVoiceOver as RecordVoiceOverIcon,
  Search as SearchIcon
} from "@mui/icons-material";
import { Box, Grid, Link, Slide, Stack, styled } from "@mui/material";
import * as yup from "yup";

import SkeletonKeyword from "@skeletons/SkeletonKeyword";

import AutoCompleteTextField from "@components/AutoCompleteTextField";
import Button from "@components/Button";
import Cursor from "@components/Cursor";
import Icon from "@components/Icon";
import NonTypeableSelect from "@components/NonTypeableSelect";
import RangeSlider from "@components/RangeSlider";
import Tag from "@components/Tag";
import TextField from "@components/TextField";
import TypeableSelect from "@components/TypeableSelect";
import Typography from "@components/Typography";

import { useOptions } from "@hooks/useOptions";
import useToast from "@hooks/useToast";

import KeyLabel from "@interfaces/components/KeyLabel";

import {
  ANIMATION_TIMEOUT_MS,
  CANDIDATE_SEARCH_SALARY_SLIDER_MAX,
  CANDIDATE_SEARCH_SALARY_SLIDER_MIN,
  CANDIDATE_SEARCH_SALARY_SLIDER_STEP,
  CANDIDATE_SEARCH_SALARY_SLIDER_TOTAL_DISTANCE,
  LANGUAGE_PROFICIENCY,
  LANGUAGE_PROFICIENCY_T_LABELS,
  YEARS_OF_EXPERIENCE,
  YEARS_OF_EXPERIENCE_T_LABELS
} from "@utils/config";
import {
  getLocationLabel,
  getLocationList
} from "@utils/keyLabelHandlers/location";
import { getSkillLabel, getSkillList } from "@utils/keyLabelHandlers/skill";
import { roundSalary } from "@utils/roundSalary";
import { colorPalette } from "@utils/theme";
import translate, { intl } from "@utils/translate";

interface SearchForm {
  keyword?: string;
  location?: KeyLabel | null;
  jaLevel?: Array<string>;
  enLevel?: Array<string>;
  skills?: Array<KeyLabel>;
  minExperience?: string;
  salaryRange?: Array<string | number>;
}

interface SearchQuery {
  label: string;
  key: string;
  query_key: string;
  query_value: string;
  icon?: string;
}

const StyledClearAllFilterContainer = styled(Stack)(({ theme }) => {
  return {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      width: 115,
      right: -119,
      transform: "translateY(-45%)",
      top: "45%"
    }
  };
});

const EmployersSearchHeader = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const containerRef = useRef<HTMLElement>(null);
  const [isSearchOpen, setSearchOpen] = useState<boolean>(false);

  const defaultSalaryRangeValue = {
    min: CANDIDATE_SEARCH_SALARY_SLIDER_MIN,
    max: CANDIDATE_SEARCH_SALARY_SLIDER_TOTAL_DISTANCE
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [formData, setFormData] = useState<SearchForm>({
    salaryRange: [defaultSalaryRangeValue.min, defaultSalaryRangeValue.max]
  });
  const [searchQueries, setSearchQueries] = useState<Array<SearchQuery>>([]);

  const toast = useToast();

  const YEARS_OF_EXPERIENCE_OPTIONS = useOptions(
    YEARS_OF_EXPERIENCE,
    YEARS_OF_EXPERIENCE_T_LABELS
  );

  const LANGUAGE_PROFICIENCY_OPTIONS = useOptions(
    LANGUAGE_PROFICIENCY,
    LANGUAGE_PROFICIENCY_T_LABELS
  );

  const schema = yup.object().shape({
    keyword: yup.string(),
    location: yup
      .object()
      .shape({
        key: yup.string(),
        label: yup.string()
      })
      .nullable(),
    jaLevel: yup
      .array()
      .of(yup.string())
      .typeError(intl.get("t_general_internal_error")),
    enLevel: yup
      .array()
      .of(yup.string())
      .typeError(intl.get("t_general_internal_error")),
    skills: yup
      .array()
      .of(
        yup.object().shape({
          key: yup.string().trim(),
          label: yup.string().trim()
        })
      )
      .typeError(intl.get("t_general_internal_error")),
    minExperience: yup.string(),
    salaryRange: yup.array()
  });

  const methods = useForm({
    defaultValues: formData,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, setValue, reset } = methods;

  const prepareData = async () => {
    try {
      const defaultValues: SearchForm = {};
      const searchQueries: Array<SearchQuery> = [];

      const keyword = searchParams.get("q");
      if (keyword) {
        defaultValues.keyword = keyword;
        searchQueries.push({
          label: keyword,
          key: "keyword",
          query_key: "q",
          query_value: keyword,
          icon: "search"
        });
      }

      const location = searchParams.get("location");
      if (location) {
        const locationLabel = await getLocationLabel(location);
        defaultValues.location = {
          key: location,
          label: locationLabel
        };
        searchQueries.push({
          label: locationLabel,
          key: "location",
          query_key: "location",
          query_value: location,
          icon: "location_on"
        });
      } else {
        // Note: This is needed otherwise it becomes an uncontrolled component
        // https://react.dev/reference/react-dom/components/input#controlling-an-input-with-a-state-variable
        defaultValues.location = null;
      }

      const jaLevel = searchParams.getAll("language_ja_level");
      if (jaLevel && jaLevel.length > 0) {
        const jaLevelValues: Array<string> = [];
        jaLevel.forEach((singleJaLevel: string) => {
          if (
            (Object.values(LANGUAGE_PROFICIENCY) as Array<string>).includes(
              singleJaLevel
            )
          ) {
            searchQueries.push({
              label: intl.get("t_search_filter_japanese_level", {
                level: intl.get(
                  LANGUAGE_PROFICIENCY_T_LABELS[
                    singleJaLevel as keyof typeof LANGUAGE_PROFICIENCY_T_LABELS
                  ]
                )
              }),
              key: "jaLevel",
              query_key: "language_ja_level",
              query_value: singleJaLevel,
              icon: "record_voice_over"
            });
            jaLevelValues.push(singleJaLevel);
          }
        });
        defaultValues.jaLevel = jaLevelValues;
      }

      const enLevel = searchParams.getAll("language_en_level");
      if (enLevel && enLevel.length > 0) {
        const enLevelValues: Array<string> = [];
        enLevel.forEach((singleEnLevel: string) => {
          if (
            (Object.values(LANGUAGE_PROFICIENCY) as Array<string>).includes(
              singleEnLevel
            )
          ) {
            searchQueries.push({
              label: intl.get("t_search_filter_english_level", {
                level: intl.get(
                  LANGUAGE_PROFICIENCY_T_LABELS[
                    singleEnLevel as keyof typeof LANGUAGE_PROFICIENCY_T_LABELS
                  ]
                )
              }),
              key: "enLevel",
              query_key: "language_en_level",
              query_value: singleEnLevel,
              icon: "forum"
            });
            enLevelValues.push(singleEnLevel);
          }
        });
        defaultValues.enLevel = enLevelValues;
      }

      const skills = searchParams.getAll("skills");
      if (skills.length > 0) {
        const defaultValueSkills: Array<KeyLabel> = [];
        for (const singleSkill of skills) {
          const skillLabel = await getSkillLabel(
            singleSkill,
            translate.getCurrentLocale()
          );
          defaultValueSkills.push({
            key: singleSkill,
            label: skillLabel
          });
          searchQueries.push({
            label: skillLabel,
            key: "skill",
            query_key: "skills",
            query_value: singleSkill,
            icon: "work"
          });
        }
        defaultValues.skills = defaultValueSkills;
      }

      const minExperience = searchParams.get("min_experience");
      if (
        minExperience &&
        (Object.keys(YEARS_OF_EXPERIENCE_T_LABELS) as Array<string>).includes(
          minExperience
        )
      ) {
        defaultValues.minExperience = minExperience;
        searchQueries.push({
          label: intl.get("t_general_minimum_experience", {
            experience: intl.get(
              YEARS_OF_EXPERIENCE_T_LABELS[
                minExperience as keyof typeof YEARS_OF_EXPERIENCE_T_LABELS
              ]
            )
          }),
          key: "minExperience",
          query_key: "min_experience",
          query_value: minExperience,
          icon: "psychology"
        });
      }

      const minSalary =
        searchParams.get("min_salary") ??
        defaultSalaryRangeValue.min?.toString();
      const maxSalary =
        searchParams.get("max_salary") ??
        defaultSalaryRangeValue.max?.toString();

      if (
        (minSalary &&
          !isNaN(parseInt(minSalary)) &&
          parseInt(minSalary) >= 0) ||
        (maxSalary && !isNaN(parseInt(maxSalary)) && parseInt(maxSalary) >= 0)
      ) {
        defaultValues.salaryRange = [parseInt(minSalary), parseInt(maxSalary)];

        const salaryRange = `${roundSalary(parseInt(minSalary))} - ${
          parseInt(maxSalary) > CANDIDATE_SEARCH_SALARY_SLIDER_MAX
            ? intl.get("t_general_any")
            : roundSalary(parseInt(maxSalary))
        }`;

        if (
          minSalary !== defaultSalaryRangeValue.min.toString() ||
          maxSalary !== defaultSalaryRangeValue.max.toString()
        ) {
          searchQueries.push({
            label: `${intl.get("t_search_filter_salary_range")} ${salaryRange}`,
            key: "salaryRange",
            query_key: "salary_range",
            query_value: salaryRange,
            icon: "currency_yen"
          });
        }
      }
      setFormData(defaultValues);
      setSearchQueries(searchQueries);
      reset(defaultValues);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.kampai(intl.get("t_general_internal_error"), "error");
    }
  };

  useEffect(() => {
    const getInitialData = async () => {
      await prepareData();
    };
    getInitialData();
  }, [translate.getCurrentLocale()]);

  const handleSearch = (formData: SearchForm) => {
    if (formData.keyword) {
      searchParams.set("q", formData.keyword);
    } else {
      searchParams.delete("q");
    }

    if (formData.location?.key) {
      searchParams.set("location", formData.location.key);
    } else {
      searchParams.delete("location");
    }

    searchParams.delete("language_ja_level");
    if (formData.jaLevel) {
      for (const singleJaLevel of formData.jaLevel) {
        searchParams.append("language_ja_level", singleJaLevel);
      }
    }

    searchParams.delete("language_en_level");
    if (formData.enLevel) {
      for (const singleEnLevel of formData.enLevel) {
        searchParams.append("language_en_level", singleEnLevel);
      }
    }

    searchParams.delete("skills");
    if (Array.isArray(formData.skills) && formData.skills?.length > 0) {
      for (const singleSkill of formData.skills) {
        searchParams.append("skills", singleSkill.key);
      }
    }

    if (formData.minExperience) {
      searchParams.set("min_experience", formData.minExperience);
    } else {
      searchParams.delete("min_experience");
    }

    if (formData.salaryRange) {
      searchParams.set(
        "min_salary",
        formData?.salaryRange?.[0]?.toString() ?? defaultSalaryRangeValue.min
      );
      searchParams.set(
        "max_salary",
        formData?.salaryRange?.[1]?.toString() ?? defaultSalaryRangeValue.max
      );
    }

    setSearchOpen(false);
    setSearchParams(searchParams);
    setIsLoading(true);
    prepareData();
  };

  const removeSearchQuery = (searchQuery: SearchQuery) => {
    if (
      searchQuery.query_key === "skills" ||
      searchQuery.query_key === "language_ja_level" ||
      searchQuery.query_key === "language_en_level"
    ) {
      const remainingValues = searchParams
        .getAll(searchQuery.query_key)
        .filter(
          (singleQueryValue: string) =>
            singleQueryValue !== searchQuery.query_value
        );

      searchParams.delete(searchQuery.query_key);
      remainingValues.forEach((singleRemainingValue: string) => {
        searchParams.append(searchQuery.query_key, singleRemainingValue);
      });

      if (remainingValues.length === 0) {
        delete formData[searchQuery.key as keyof SearchForm];
      }
      if (searchQuery.query_key === "skills") {
        const remainingSkills = formData.skills?.filter(
          (singleSkill: KeyLabel) => singleSkill.key !== searchQuery.query_value
        );
        formData.skills = remainingSkills;
      } else if (searchQuery.query_key === "language_ja_level") {
        const remainingJaLevel = formData.jaLevel?.filter(
          (singleJaLevel: string) => singleJaLevel !== searchQuery.query_value
        );
        formData.jaLevel = remainingJaLevel;
      } else if (searchQuery.query_key === "language_en_level") {
        const remainingEnLevel = formData.enLevel?.filter(
          (singleEnLevel: string) => singleEnLevel !== searchQuery.query_value
        );
        formData.enLevel = remainingEnLevel;
      }
    } else {
      searchParams.delete(searchQuery.query_key);
      delete formData[searchQuery.key as keyof SearchForm];
    }

    const filteredSearchQueries = searchQueries.filter(
      (singleSearchQuery: SearchQuery) =>
        singleSearchQuery.query_value !== searchQuery.query_value ||
        singleSearchQuery.query_key !== searchQuery.query_key
    );

    searchParams.set("page", "1");
    setFormData(formData);
    setSearchQueries(filteredSearchQueries);
    reset(formData);
    setSearchParams(searchParams);
  };

  /* FIXME: if a new filter is added in the future, 
  then clear all function needs to change 
  as it explicitly needs which filters to remove. 
  (It doesn't remove all the query parameters)*/
  const clearAllFilters = () => {
    searchParams.delete("q");
    searchParams.delete("location");
    searchParams.delete("language_ja_level");
    searchParams.delete("language_en_level");
    searchParams.delete("skills");
    searchParams.delete("min_experience");
    searchParams.set("min_salary", defaultSalaryRangeValue.min?.toString());
    searchParams.set("max_salary", defaultSalaryRangeValue.max?.toString());

    formData.keyword = "";
    formData.location = { key: "", label: "" };
    formData.salaryRange = [
      defaultSalaryRangeValue.min,
      defaultSalaryRangeValue.max
    ];

    formData.minExperience = "";
    formData.jaLevel = [];
    formData.enLevel = [];
    formData.skills = [];

    setSearchOpen(false);
    searchParams.set("page", "1");
    setFormData(formData);
    reset(formData);
    setSearchParams(searchParams);
    setIsLoading(true);
    prepareData();
  };

  const cancelSearch = () => {
    reset(formData);
    setSearchOpen(false);
  };

  return (
    <Box py={2} px={4} borderRadius={1} bgcolor="common.white">
      {/* FIXME: Fix the Typography here */}
      <Typography
        variant="subtitle2"
        fontWeight="bold"
        mr={1}
        sx={{ verticalAlign: "middle" }}>
        <Icon type="filter_alt" filled />
      </Typography>

      {/* For the Title */}
      <Typography variant="subtitle2" fontWeight="bold">
        {isSearchOpen
          ? intl.get("t_job_search_filter_by")
          : intl.get("t_job_search_filter_results")}
      </Typography>

      {/* For the Icon */}
      {!isSearchOpen ? (
        <Box
          onClick={() => {
            setSearchOpen(true);
          }}
          sx={{ float: "right" }}>
          <Cursor>
            <BorderColorIcon
              sx={{
                "color": colorPalette.black.base,
                "&:hover": {
                  color: colorPalette.blue.base
                }
              }}
            />
          </Cursor>
        </Box>
      ) : (
        false
      )}

      {/* For the Search Form */}
      <Box
        mt={2}
        position="relative"
        mr={{ md: !isSearchOpen ? 15 : "unset" }}
        overflow="hidden">
        {isSearchOpen ? (
          <Slide
            container={containerRef.current}
            in={isSearchOpen}
            direction="down"
            {...(isSearchOpen ? { timeout: ANIMATION_TIMEOUT_MS.FAST } : {})}>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(handleSearch)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  {/* NOTE: In future, this should be an auto suggest box based on the job title we have */}
                  <TextField
                    name="keyword"
                    control={control}
                    placeholder={intl.get("t_general_job_title")}
                    sx={{ textTransform: "capitalize" }}
                    startAdornment={<SearchIcon height={24} width={24} />}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AutoCompleteTextField
                    name="location"
                    control={control}
                    setValue={setValue}
                    placeholder={intl.get("t_general_search_location")}
                    startAdornment={<LocationOnIcon height={24} width={24} />}
                    getOptions={getLocationList}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <NonTypeableSelect
                    setValue={setValue}
                    control={control}
                    name="jaLevel"
                    multiSelect
                    label={intl.get("t_job_search_placeholder_japanese_level")}
                    placeholder={intl.get(
                      "t_job_search_placeholder_japanese_level"
                    )}
                    startAdornment={
                      <RecordVoiceOverIcon height={24} width={24} />
                    }
                    options={LANGUAGE_PROFICIENCY_OPTIONS}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <NonTypeableSelect
                    setValue={setValue}
                    control={control}
                    name="enLevel"
                    multiSelect
                    label={intl.get("t_job_search_placeholder_english_level")}
                    placeholder={intl.get(
                      "t_job_search_placeholder_english_level"
                    )}
                    startAdornment={<ForumIcon height={24} width={24} />}
                    options={LANGUAGE_PROFICIENCY_OPTIONS}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <NonTypeableSelect
                    setValue={setValue}
                    control={control}
                    name="minExperience"
                    label={intl.get("t_profile_summary_experience")}
                    placeholder={intl.get("t_profile_summary_experience")}
                    startAdornment={<PsychologyIcon height={24} width={24} />}
                    options={YEARS_OF_EXPERIENCE_OPTIONS}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TypeableSelect
                    control={control}
                    name="skills"
                    multiple
                    label={intl.get("t_general_skills")}
                    placeholder={intl.get("t_general_skills")}
                    options={[]}
                    getOptions={getSkillList}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box mt={{ sm: 3 }} px={1}>
                    <RangeSlider
                      name="salaryRange"
                      label={intl.get(
                        "t_employer_search_candidate_salary_range_filter_title"
                      )}
                      control={control}
                      min={defaultSalaryRangeValue.min}
                      max={defaultSalaryRangeValue.max}
                      step={CANDIDATE_SEARCH_SALARY_SLIDER_STEP}
                      minDistance={CANDIDATE_SEARCH_SALARY_SLIDER_STEP}
                      valueLabelFormat={(value: number) => {
                        return value > CANDIDATE_SEARCH_SALARY_SLIDER_MAX
                          ? intl.get("t_general_any")
                          : roundSalary(value);
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row-reverse" spacing={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isLoading}>
                      {intl.get("t_general_view_results")}
                    </Button>
                    <Button
                      variant="outlined"
                      handleClick={cancelSearch}
                      type="reset">
                      {intl.get("t_general_cancel")}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Slide>
        ) : (
          <>
            {isLoading ? (
              <SkeletonKeyword multiple />
            ) : (
              <>
                <Box
                  display={{ md: "flex" }}
                  overflow={{ md: "auto" }}
                  whiteSpace={{ md: "nowrap" }}>
                  <Stack
                    direction="row"
                    gap={1}
                    overflow="auto"
                    flexShrink={0}
                    whiteSpace="nowrap">
                    {searchQueries.map(
                      (singleSearchQuery: SearchQuery, index: number) => (
                        <Tag
                          key={index}
                          label={singleSearchQuery.label}
                          startAdornment={
                            singleSearchQuery.icon ? (
                              <Typography variant="subtitle3">
                                <Icon type={singleSearchQuery.icon} filled />
                              </Typography>
                            ) : (
                              false
                            )
                          }
                          endAdornment={
                            <Box
                              component="span"
                              onClick={() =>
                                removeSearchQuery(singleSearchQuery)
                              }>
                              <Cursor>
                                <CloseIcon fontSize="small" />
                              </Cursor>
                            </Box>
                          }
                        />
                      )
                    )}
                  </Stack>
                </Box>
                {searchQueries.length > 0 ? (
                  <StyledClearAllFilterContainer
                    flexDirection="row"
                    justifyContent="flex-end"
                    ml={1}
                    mt={{ xs: 1, md: "unset" }}>
                    <Link
                      component="button"
                      variant="subtitle5"
                      onClick={() => {
                        clearAllFilters();
                      }}>
                      {intl.get("t_search_header_clear_all_filters")}
                    </Link>
                  </StyledClearAllFilterContainer>
                ) : (
                  false
                )}
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default EmployersSearchHeader;

import { useEffect } from "react";

import { Outlet } from "react-router-dom";

import { Grid } from "@mui/material";
import useForceUpdate from "use-force-update";

import SideMenu from "@components/SideMenu";
import Typography from "@components/Typography";

import { ENVIRONMENT } from "@utils/config";
import translate, { intl } from "@utils/translate";

const index = () => {
  const menuLinks = [
    {
      label: intl.get("t_account_setting_side_menu_account_setting"),
      redirectLink: "/employers/settings/account-settings"
    },
    {
      label: intl.get("t_account_setting_side_menu_company_setting"),
      redirectLink: "/employers/settings/company-settings"
    },
    {
      label: intl.get("t_account_setting_side_menu_resume_purchase"),
      redirectLink: "/employers/settings/resume-purchases"
    },
    {
      label: intl.get("t_account_setting_side_menu_manage_team"),
      redirectLink: "/employers/settings/manage-team"
    },
    {
      label: intl.get("t_account_setting_side_menu_plan"),
      redirectLink: "/employers/settings/plan",
      envSpecific: ENVIRONMENT.STAGE
    }
  ];

  const forceUpdate = useForceUpdate();
  const languageChanged = () => {
    forceUpdate();
  };

  useEffect(() => {
    translate.addLanguageChangedListener(languageChanged);
    return () => {
      translate.removeLanguageChangedListener(languageChanged);
    };
  }, []);

  return (
    <>
      <Typography variant="h3" color="white">
        {intl.get("t_account_setting_page_title")}
      </Typography>

      <Grid container mt={4} rowGap={3}>
        <Grid item xs={12} md={3} pr={{ xs: 0, md: 2 }}>
          {/* SideMenu */}
          <SideMenu menuLinks={menuLinks} />
        </Grid>
        <Grid item xs={12} md={9} pl={{ xs: 0, md: 2 }}>
          <Outlet />
        </Grid>
      </Grid>
    </>
  );
};

export default index;

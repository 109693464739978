import { useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import EducationForm from "@components/ResumeForms/EducationForm";

import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import UserEducation from "@interfaces/database/UserEducation";

import {
  INDIVIDUAL_USER_DEGREE_TYPE,
  LOCALE,
  LOCALE_SHORT,
  MAX_EDUCATIONS
} from "@utils/config";
import { prepareMultiLingual, resolveMultiLingual } from "@utils/multiLingual";
import Timestamp from "@utils/Timestamp";
import translate, { intl } from "@utils/translate";

interface EducationFormData {
  institutionName?: string;
  degreeType?:
    | string
    | typeof INDIVIDUAL_USER_DEGREE_TYPE[keyof typeof INDIVIDUAL_USER_DEGREE_TYPE];
  fieldOfStudy?: string;
  startDate?: Date | string;
  endDate?: Date | string;
}

const JapaneseResumeEditEducation = () => {
  const userData = useUserProfile();
  const navigate = useNavigate();
  const toast = useToast();
  const { item_id } = useParams();
  const itemId = Number(item_id);

  //Fetch the education data based on valid itemId
  const userEducations = userData.value?.cv?.education ?? [];
  const education = itemId >= 0 ? userEducations?.[itemId] : undefined;

  //If the itemId is not valid, re-route to Add Form Page
  useEffect(() => {
    if (item_id && !education) {
      navigate(
        `/${translate.getCurrentLocale()}/profile/resume/ja-cv/edit/education`,
        { replace: true }
      );
    }
  }, []);

  //Setting initial form values
  const initialValues = {
    institutionName:
      resolveMultiLingual(education?.institute_name, LOCALE.JA) ?? "",
    degreeType: education?.degree_type ?? "",
    fieldOfStudy:
      resolveMultiLingual(education?.field_of_study, LOCALE.JA) ?? "",
    startDate: education?.timeline?.start?.toDate() ?? "",
    endDate: education?.timeline?.end?.toDate() ?? ""
  };

  const handleEducationSavedSuccess = () => {
    toast.kampai(intl.get("t_resumes_toast_success_update"), "success");
  };

  const handleEducationSavedFail = () => {
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleAdd = async (formData: EducationFormData) => {
    const { institutionName, degreeType, fieldOfStudy, startDate, endDate } =
      formData || {};

    const education: UserEducation = {
      institute_name: prepareMultiLingual(
        (institutionName as string) ?? "",
        LOCALE.JA
      ),
      degree_type:
        degreeType as typeof INDIVIDUAL_USER_DEGREE_TYPE[keyof typeof INDIVIDUAL_USER_DEGREE_TYPE],
      field_of_study: prepareMultiLingual(
        (fieldOfStudy as string) ?? "",
        LOCALE.JA
      ),
      timeline: {
        start: Timestamp.fromDate(startDate as Date),
        end: Timestamp.fromDate(endDate as Date)
      }
    };

    try {
      if (userData.setValue && userData.value) {
        if (!userData.value?.cv) {
          userData.value.cv = { updated_at: Timestamp.now() };
        }

        if (!userData.value?.cv?.education) {
          userData.value.cv.education = [];
        }

        if (userData.value.cv.education.length >= MAX_EDUCATIONS) {
          toast.kampai(
            intl.get("t_toast_error_limit_exceed", {
              fieldName: intl.get("t_resumes_education_title"),
              maxLimit: MAX_EDUCATIONS
            }),
            "error"
          );

          return;
        }

        await userData.setValue(
          {
            ...userData.value,
            cv: {
              ...userData.value?.cv,
              education: [...userData.value.cv.education, education]
            },
            updated_at: Timestamp.now()
          },
          handleEducationSavedSuccess,
          handleEducationSavedFail
        );
        navigate(`/${translate.getCurrentLocale()}/profile/resume/ja-cv`);
      }
    } catch (e) {
      handleEducationSavedFail();
    }
  };

  const handleEdit = async (formData: EducationFormData) => {
    const { institutionName, degreeType, fieldOfStudy, startDate, endDate } =
      formData || {};

    const existingEducation = userEducations[itemId];

    try {
      if (
        item_id &&
        userData.setValue &&
        userData.value &&
        userData.value?.cv?.education &&
        existingEducation
      ) {
        const education: UserEducation = {
          institute_name: prepareMultiLingual(
            (institutionName as string) ?? "",
            LOCALE.JA,
            existingEducation.institute_name
          ),
          degree_type:
            degreeType as typeof INDIVIDUAL_USER_DEGREE_TYPE[keyof typeof INDIVIDUAL_USER_DEGREE_TYPE],
          field_of_study: prepareMultiLingual(
            (fieldOfStudy as string) ?? "",
            LOCALE.JA,
            existingEducation.field_of_study
          ),
          timeline: {
            start: Timestamp.fromDate(startDate as Date),
            end: Timestamp.fromDate(endDate as Date)
          }
        };

        userEducations[itemId] = education;

        await userData.setValue(
          {
            ...userData.value,
            cv: {
              ...userData.value?.cv,
              education: userEducations
            }
          },
          handleEducationSavedSuccess,
          handleEducationSavedFail
        );
        navigate(`/${translate.getCurrentLocale()}/profile/resume/ja-cv`);
      }
    } catch (e) {
      handleEducationSavedFail();
    }
  };

  return (
    <EducationForm
      handleFormSubmit={item_id ? handleEdit : handleAdd}
      initialValues={initialValues}
      backBtnLink={`/${translate.getCurrentLocale()}/profile/resume/ja-cv`}
      formLanguage={LOCALE_SHORT.JA}
    />
  );
};

export default JapaneseResumeEditEducation;

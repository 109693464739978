import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon
} from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import deepEqual from "deep-equal";
import { ContentState, convertToRaw, RawDraftContentState } from "draft-js";
import * as yup from "yup";

import SkeletonJobInformationForm from "@skeletons/EmployersPostJob/SkeletonJobInformationForm";
import SkeletonButton from "@skeletons/SkeletonButton";

import Button from "@components/Button";
import Paper from "@components/Paper";
import TextField from "@components/TextField";
import Typography from "@components/Typography";
import WYSIWYG from "@components/WYSIWYG";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import useToast from "@hooks/useToast";

import JobID from "@interfaces/database/JobID";
import JobProfile from "@interfaces/database/JobProfile";

import {
  FREE_TEXT_FIELD_MAX_LENGTH,
  JOB_DESCRIPTION_FIELD_MAX_LENGTH,
  MUST_REQUIREMENTS_FIELD_MAX_LENGTH,
  TOTAL_JOB_POSTING_STEPS,
  WYSIWYG_URL_REGEX
} from "@utils/config";
import { rawDraftContentStateToString } from "@utils/convertDraftContentState";
import { prepareMultiLingual, resolveMultiLingual } from "@utils/multiLingual";
import Timestamp from "@utils/Timestamp";
import translate, { intl } from "@utils/translate";

interface JobInformationFormData {
  jobTitle: string;
  jobDescription: RawDraftContentState;
  mustRequirements: RawDraftContentState;
}

const JobInformation = () => {
  const { job_id: jobId } = useParams();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const companyDetails = useCompanyDetails();
  const toast = useToast();

  const isCompanyDetailsLoading = companyDetails.loading;
  const currentLocale = translate.getCurrentLocale();

  const {
    jobData,
    handleSetJobData
  }: {
    jobData: JobProfile;
    handleSetJobData: (
      jobId: JobID,
      jobData: JobProfile,
      handleJobUpdateSuccess: (jobId: string) => void,
      handleJobUpdateFail: () => void
    ) => void;
  } = useOutletContext();

  // validation schema
  const schema = yup.object({
    jobTitle: yup
      .string()
      .trim()
      .max(
        FREE_TEXT_FIELD_MAX_LENGTH,
        intl.get("t_error_max_limit", {
          field: intl.get("t_job_post_job_information_step_job_title"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        })
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_job_post_job_information_step_job_title")
        })
      ),
    jobDescription: yup
      .object()
      .shape({
        blocks: yup.array().of(yup.object()).min(1),
        entityMap: yup.object()
      })
      .test(
        "jobDescription",
        intl.get("t_error_required", {
          field: intl.get("t_job_post_job_information_step_job_description")
        }),
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (rawDraftState: any) =>
          rawDraftContentStateToString(rawDraftState).length > 0
      )
      .test(
        "jobDescription",
        intl.get("t_error_max_limit", {
          field: intl.get("t_job_post_job_information_step_job_description"),
          maxLimit: JOB_DESCRIPTION_FIELD_MAX_LENGTH
        }),
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (rawDraftState: any) =>
          rawDraftContentStateToString(rawDraftState).length <=
          JOB_DESCRIPTION_FIELD_MAX_LENGTH
      )
      .test(
        "jobDescription",
        intl.get("t_error_urls_are_prohibited"),
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (rawDraftState: any) => {
          const isLinkNotFound = Object.values(rawDraftState.entityMap).every(
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            (singleNode: any) => {
              singleNode.type !== "LINK";
            }
          );
          return isLinkNotFound;
        }
      )
      .test(
        "jobDescription",
        intl.get("t_error_urls_are_prohibited"),
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (rawDraftState: any) => {
          const text = rawDraftContentStateToString(rawDraftState);
          return !WYSIWYG_URL_REGEX.test(text);
        }
      ),
    mustRequirements: yup
      .object()
      .shape({
        blocks: yup.array().of(yup.object()).min(1),
        entityMap: yup.object()
      })
      .test(
        "mustRequirements",
        intl.get("t_error_required", {
          field: intl.get("t_job_post_job_information_step_must_requirement")
        }),
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (rawDraftState: any) =>
          rawDraftContentStateToString(rawDraftState).length > 0
      )
      .test(
        "mustRequirements",
        intl.get("t_error_max_limit", {
          field: intl.get("t_job_post_job_information_step_must_requirement"),
          maxLimit: MUST_REQUIREMENTS_FIELD_MAX_LENGTH
        }),
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (rawDraftState: any) =>
          rawDraftContentStateToString(rawDraftState).length <=
          MUST_REQUIREMENTS_FIELD_MAX_LENGTH
      )
      .test(
        "mustRequirements",
        intl.get("t_error_urls_are_prohibited"),
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (rawDraftState: any) => {
          const isLinkNotFound = Object.values(rawDraftState.entityMap).every(
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            (singleNode: any) => {
              singleNode.type !== "LINK";
            }
          );
          return isLinkNotFound;
        }
      )
      .test(
        "mustRequirements",
        intl.get("t_error_urls_are_prohibited"),
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (rawDraftState: any) => {
          const text = rawDraftContentStateToString(rawDraftState);
          return !WYSIWYG_URL_REGEX.test(text);
        }
      )
  });

  const formInitValues = {
    jobTitle: resolveMultiLingual(jobData?.job_title) ?? "",
    jobDescription:
      resolveMultiLingual(jobData?.job_description) ??
      convertToRaw(ContentState.createFromText("")),
    mustRequirements:
      resolveMultiLingual(jobData?.must_requirements) ??
      convertToRaw(ContentState.createFromText(""))
  };

  const methods = useForm({
    defaultValues: formInitValues,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, reset } = methods;

  useEffect(() => {
    reset(formInitValues);
  }, [
    jobData?.job_title,
    jobData?.job_description,
    jobData?.must_requirements,
    currentLocale
  ]);

  const handleJobUpdateSuccess = (updatedJobId: string) => {
    setIsDisabled(false);
    navigate(
      `/${translate.getCurrentLocale()}/employers/jobs/${updatedJobId}/skill-requirement`
    );
  };

  const handleJobUpdateFail = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleOnSubmit = async (formData: JobInformationFormData) => {
    const isFormValueChanged = !deepEqual(formData, formInitValues);
    try {
      setIsDisabled(true);
      const { jobTitle, jobDescription, mustRequirements } = formData || {};
      if (companyDetails?.value && !companyDetails?.value?.jobs) {
        companyDetails.value.jobs = [];
      }
      if (jobId && companyDetails?.value?.jobs && isFormValueChanged) {
        handleSetJobData(
          jobId,
          {
            ...jobData,
            job_title: prepareMultiLingual(
              jobTitle,
              undefined,
              jobData?.job_title
            ),
            job_description: prepareMultiLingual(
              jobDescription,
              undefined,
              jobData?.job_description
            ),
            must_requirements: prepareMultiLingual(
              mustRequirements,
              undefined,
              jobData?.job_description
            ),
            updated_at: Timestamp.now()
          },
          handleJobUpdateSuccess,
          handleJobUpdateFail
        );
      } else if (jobId) {
        handleJobUpdateSuccess(jobId);
      }
    } catch (e) {
      handleJobUpdateFail();
    }
  };

  return (
    <Box noValidate component="form" onSubmit={handleSubmit(handleOnSubmit)}>
      <Paper>
        <Stack direction={{ xs: "column", md: "row" }} gap={{ md: 10 }}>
          <Stack width={{ xs: "100%", md: "40%" }}>
            <Typography
              variant="subtitle2"
              mb={{ xs: 3, md: 8 }}
              color="secondary.main">
              {intl.get("t_general_step", { stepNumber: 2 })}/
              {TOTAL_JOB_POSTING_STEPS}
            </Typography>
            <Typography variant="h4" mb={2.5}>
              {intl.get("t_job_post_job_information_step_label")}
            </Typography>
          </Stack>
          <Stack width={{ xs: "100%", md: "60%" }}>
            {isCompanyDetailsLoading ? (
              <SkeletonJobInformationForm />
            ) : (
              <>
                <TextField
                  data-testid="job_information_job_title_input"
                  control={control}
                  name="jobTitle"
                  label={intl.get("t_job_post_job_information_step_job_title")}
                  placeholder={intl.get(
                    "t_job_post_job_information_step_job_title"
                  )}
                  required
                  disabled={isDisabled}
                />
                <Box data-testid="job_information_job_description_box">
                  <WYSIWYG
                    mode="edit"
                    control={control}
                    name="jobDescription"
                    label={intl.get(
                      "t_job_post_job_information_step_job_description"
                    )}
                    placeholder={intl.get(
                      "t_job_post_job_information_step_job_description"
                    )}
                    required
                    disabled={isDisabled}
                    maxLength={JOB_DESCRIPTION_FIELD_MAX_LENGTH}
                  />
                </Box>
                <Box mt={2} data-testid="job_information_must_requirements_box">
                  <WYSIWYG
                    disabled={isDisabled}
                    control={control}
                    mode="edit"
                    name="mustRequirements"
                    label={intl.get(
                      "t_job_post_job_information_step_must_requirement"
                    )}
                    placeholder={intl.get(
                      "t_job_post_job_information_step_must_requirement_placeholder"
                    )}
                    required
                    maxLength={MUST_REQUIREMENTS_FIELD_MAX_LENGTH}
                  />
                </Box>
              </>
            )}
          </Stack>
        </Stack>
      </Paper>
      <br />
      <Stack direction="row" justifyContent="space-between">
        {isCompanyDetailsLoading ? (
          <>
            <SkeletonButton />
            <SkeletonButton />
          </>
        ) : (
          <>
            <Button
              data-testid="job_information_back_button"
              variant="outlined"
              startAdornment={<NavigateBeforeIcon />}
              handleClick={() =>
                navigate(
                  `/${translate.getCurrentLocale()}/employers/jobs/${jobId}/company-information`
                )
              }>
              {intl.get("t_general_back")}
            </Button>
            <Button
              data-testid="job_information_next_button"
              loading={isDisabled}
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              endAdornment={<NavigateNextIcon />}>
              {intl.get("t_general_save_and_next")}
            </Button>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default JobInformation;

import { useState } from "react";

import { Box, DialogContent, Divider, Link } from "@mui/material";

import BasicInformation from "@components/Debugger/BasicInformation";
import Chat from "@components/Debugger/Chat";
import GenerateCVPdf from "@components/Debugger/GenerateCVPdf";
import Invite from "@components/Debugger/Invite";
import Jobs from "@components/Debugger/Jobs";
import LanguageSwitcher from "@components/Debugger/LanguageSwitcher";
import Login from "@components/Debugger/Login";
import LottieAnimation from "@components/Debugger/LottieAnimation";
import Navigation from "@components/Debugger/Navigation";
import Other from "@components/Debugger/Other";
import ProfileData from "@components/Debugger/ProfileData";
import PurchaseResume from "@components/Debugger/PurchaseResume";
import Skills from "@components/Debugger/Skills";
import Translator from "@components/Debugger/Translator";
import ViewResume from "@components/Debugger/ViewResume";
import Dialog from "@components/Dialog";
import { Tab, TabPanel, Tabs } from "@components/Tab";
import Typography from "@components/Typography";

import useUserProfile from "@hooks/database/useUserProfile";

import { USER_TYPE } from "@utils/config";
import { colorPalette } from "@utils/theme";
import { intl } from "@utils/translate";

const Debugger = () => {
  const [activeTabValue, setActiveTabValue] = useState<number>(0);
  const userProfile = useUserProfile();
  const isLoggedIn = userProfile.value;
  const isEmployer =
    userProfile?.value?.user_type === USER_TYPE.COMPANY ||
    userProfile?.value?.user_type === USER_TYPE.COMPANY_MANAGER;

  return (
    <Dialog
      initiator={
        <Box
          component="div"
          position="absolute"
          top={0}
          left={5}
          bgcolor={colorPalette.black.hover[50]}
          paddingX={1}
          zIndex={15}>
          <Typography variant="body1">
            <Link href="#">{intl.get("t_debugger_title")}</Link>
          </Typography>
        </Box>
      }
      title={`${intl.get("t_debugger_title")} - ${
        process.env.REACT_APP_VERSION
      }`}
      maxWidth="xl">
      {() => (
        <DialogContent>
          <Tabs value={activeTabValue}>
            <Tab
              value={0}
              isActive={activeTabValue === 0}
              changeHandler={() => setActiveTabValue(0)}>
              {intl.get("t_profile_basic_info_heading")}
            </Tab>
            <Tab
              value={1}
              isActive={activeTabValue === 1}
              changeHandler={() => setActiveTabValue(1)}>
              {intl.get("t_debugger_language_switcher")}
            </Tab>
            <Tab
              value={2}
              isActive={activeTabValue === 2}
              changeHandler={() => setActiveTabValue(2)}>
              {intl.get("t_general_login")}
            </Tab>
            <Tab
              value={3}
              isActive={activeTabValue === 3}
              changeHandler={() => setActiveTabValue(3)}>
              {intl.get("t_debugger_profile_data")}
            </Tab>
            <Tab
              value={4}
              isActive={activeTabValue === 4}
              changeHandler={() => setActiveTabValue(4)}>
              {intl.get("t_debugger_navigation")}
            </Tab>
            <Tab
              disabled={!isEmployer}
              value={6}
              isActive={activeTabValue === 6}
              changeHandler={() => setActiveTabValue(6)}>
              Jobs
            </Tab>
            <Tab
              disabled={!isEmployer}
              value={7}
              isActive={activeTabValue === 7}
              changeHandler={() => setActiveTabValue(7)}>
              View Resume
            </Tab>
            <Tab
              value={8}
              disabled={!isEmployer}
              isActive={activeTabValue === 8}
              changeHandler={() => setActiveTabValue(8)}>
              Purchase Resume
            </Tab>
            <Tab
              disabled={isLoggedIn === undefined}
              value={9}
              isActive={activeTabValue === 9}
              changeHandler={() => setActiveTabValue(9)}>
              Skills
            </Tab>
            <Tab
              value={10}
              disabled={!isEmployer}
              isActive={activeTabValue === 10}
              changeHandler={() => setActiveTabValue(10)}>
              Invite
            </Tab>
            <Tab
              disabled={isEmployer}
              value={11}
              isActive={activeTabValue === 11}
              changeHandler={() => setActiveTabValue(11)}>
              Generate CV
            </Tab>
            <Tab
              disabled={isEmployer}
              value={12}
              isActive={activeTabValue === 12}
              changeHandler={() => setActiveTabValue(12)}>
              Translator
            </Tab>
            <Tab
              disabled={!isEmployer}
              value={13}
              isActive={activeTabValue === 13}
              changeHandler={() => setActiveTabValue(13)}>
              Conversation
            </Tab>
            <Tab
              value={14}
              isActive={activeTabValue === 14}
              changeHandler={() => setActiveTabValue(14)}>
              Lottie Animation
            </Tab>
            <Tab
              value={5}
              isActive={activeTabValue === 5}
              changeHandler={() => setActiveTabValue(5)}>
              Other
            </Tab>
          </Tabs>
          <Divider />
          <br />
          <TabPanel value={activeTabValue} index={0}>
            <BasicInformation />
          </TabPanel>
          <TabPanel value={activeTabValue} index={1}>
            <LanguageSwitcher />
          </TabPanel>
          <TabPanel value={activeTabValue} index={2}>
            <Login />
          </TabPanel>
          <TabPanel value={activeTabValue} index={3}>
            <ProfileData />
          </TabPanel>
          <TabPanel value={activeTabValue} index={4}>
            <Navigation />
          </TabPanel>
          <TabPanel value={activeTabValue} index={6}>
            <Jobs />
          </TabPanel>
          <TabPanel value={activeTabValue} index={7}>
            <ViewResume />
          </TabPanel>
          <TabPanel value={activeTabValue} index={5}>
            <Other />
          </TabPanel>
          <TabPanel value={activeTabValue} index={8}>
            <PurchaseResume />
          </TabPanel>
          <TabPanel value={activeTabValue} index={9}>
            <Skills />
          </TabPanel>
          <TabPanel value={activeTabValue} index={10}>
            <Invite />
          </TabPanel>
          <TabPanel value={activeTabValue} index={13}>
            <Chat />
          </TabPanel>
          <TabPanel value={activeTabValue} index={11}>
            <GenerateCVPdf />
          </TabPanel>
          <TabPanel value={activeTabValue} index={12}>
            <Translator />
          </TabPanel>
          <TabPanel value={activeTabValue} index={14}>
            <LottieAnimation />
          </TabPanel>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default Debugger;

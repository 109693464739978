import { useEffect, useState } from "react";

import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import {
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon
} from "@mui/icons-material";
import { Stack } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";

import SkeletonButton from "@skeletons/SkeletonButton";
import SkeletonJobDetailCard from "@skeletons/SkeletonJobDetailCard";

import Button from "@components/Button";
import JobDetailCard from "@components/JobDetailCard";
import Paper from "@components/Paper";
import Typography from "@components/Typography";

import useToast from "@hooks/useToast";

import CompanyProfile from "@interfaces/database/CompanyProfile";
import JobID from "@interfaces/database/JobID";
import JobProfile from "@interfaces/database/JobProfile";
import UploadedFileInformation from "@interfaces/database/UploadedFileInformation";
import JobProfileWrapped from "@interfaces/functions/JobProfileWrapped";

import {
  FILES_LOCATION_COMPANY_LOGO,
  FIRESTORE_COLLECTIONS,
  JOB_APPLY_STATUS_CODE,
  JOB_NEW_TAG_LAST_UPDATED_DAY,
  JOB_STATUS_TAGS,
  TOTAL_JOB_POSTING_STEPS
} from "@utils/config";
import { getDaysAgo } from "@utils/dateTime";
import { db } from "@utils/firebase";
import { getFileURL } from "@utils/getFileURL";
import { resolveMultiLingual } from "@utils/multiLingual";
import Timestamp from "@utils/Timestamp";
import translate, { intl } from "@utils/translate";

const Confirmation = () => {
  const { job_id: jobId } = useParams();
  const navigate = useNavigate();
  const {
    jobData,
    handleSetJobData
  }: {
    jobData: JobProfile;
    handleSetJobData: (
      jobId: JobID,
      jobData: JobProfile,
      handleJobUpdateSuccess: (jobId: JobID) => void,
      handleJobUpdateFail: () => void
    ) => void;
  } = useOutletContext();
  const toast = useToast();

  const [jobDetails, setJobDetails] = useState<JobProfileWrapped>();
  const [isJobDataLoading, setIsJobDataLoading] = useState<boolean>(true);
  const [jobAdditionalFileMetaData, setJobAdditionalFileMetaData] = useState<
    UploadedFileInformation | undefined
  >(jobData?.additional_file);

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const handleJobUpdateSuccess = (updatedJobId: string) => {
    setIsDisabled(false);
    navigate(
      `/${translate.getCurrentLocale()}/employers/jobs/${updatedJobId}/success`
    );
    toast.kampai(intl.get("t_toast_success_job_post"), "success");
  };

  const handleJobUpdateFail = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  useEffect(() => {
    if (!jobId) {
      return;
    }
    setIsJobDataLoading(true);
    (async () => {
      if (jobData && jobData.company_id) {
        const companyRef = doc(
          db,
          FIRESTORE_COLLECTIONS.COMPANIES,
          jobData.company_id
        );
        const companySnap = await getDoc(companyRef);
        if (companySnap.exists()) {
          let companyData = companySnap.data() as CompanyProfile;

          let agencyData;
          if (jobData.client_company_id) {
            const clientCompanyRef = doc(
              db,
              FIRESTORE_COLLECTIONS.COMPANIES,
              jobData.client_company_id
            );
            const clientCompanySnap = await getDoc(clientCompanyRef);
            if (clientCompanySnap.exists()) {
              agencyData = companyData;
              companyData = clientCompanySnap.data() as CompanyProfile;
            }
          }

          setJobDetails({
            job_id: jobId,
            job_profile: jobData,
            company_profile: companyData,
            agency_profile: agencyData
          });
        }
      }
      setIsJobDataLoading(false);
    })();
  }, [jobId, jobData]);

  const statusTags: Array<
    typeof JOB_STATUS_TAGS[keyof typeof JOB_STATUS_TAGS]
  > = [JOB_STATUS_TAGS.ACTIVE];
  if (jobDetails?.job_profile.updated_at) {
    if (
      getDaysAgo(jobDetails?.job_profile.updated_at) <=
      JOB_NEW_TAG_LAST_UPDATED_DAY
    ) {
      statusTags.push(JOB_STATUS_TAGS.NEW);
    }
  }

  useEffect(() => {
    setJobAdditionalFileMetaData(jobData?.additional_file);
  }, [jobData?.additional_file]);

  return (
    <>
      <Paper>
        <Stack direction={{ xs: "column", md: "row" }} gap={{ md: 10 }}>
          <Stack width={{ xs: "100%", md: "25%" }}>
            <Typography
              variant="subtitle2"
              mb={{ xs: 3, md: 8 }}
              color="secondary.main">
              {intl.get("t_general_step", { stepNumber: 8 })}/
              {TOTAL_JOB_POSTING_STEPS}
            </Typography>
            <Typography variant="h4" mb={2.5}>
              {intl.get("t_job_post_job_confirmation_step_label")}
            </Typography>
          </Stack>
          <Stack width={{ xs: "100%", md: "75%" }}>
            {isJobDataLoading || !jobDetails || !jobData ? (
              <SkeletonJobDetailCard />
            ) : (
              <JobDetailCard
                mode="employer_preview"
                visaSponsorship={
                  jobDetails?.job_profile?.job_overview?.visa_sponsorship
                }
                jobContractType={
                  jobDetails?.job_profile?.job_overview?.contract_type
                }
                remoteAllowed={
                  jobDetails?.job_profile?.job_overview?.remote_possible
                }
                jaProficiency={
                  jobDetails?.job_profile?.language_requirement?.at(0)
                    ?.proficiency
                }
                enProficiency={
                  jobDetails?.job_profile?.language_requirement?.at(1)
                    ?.proficiency
                }
                jobDescription={resolveMultiLingual(
                  jobDetails?.job_profile?.job_description
                )}
                experienceRequirement={
                  jobDetails?.job_profile.job_overview?.minimum_experience
                }
                mustRequirements={resolveMultiLingual(
                  jobDetails?.job_profile?.must_requirements
                )}
                languageRequirement={
                  jobDetails.job_profile.language_requirement
                }
                jobApplyStatusCode={JOB_APPLY_STATUS_CODE.HIDDEN}
                jobStatuses={statusTags}
                jobId={jobId ?? ""}
                updatedDate={
                  new Date(jobDetails.job_profile.updated_at.seconds * 1000)
                }
                companyLogo={getFileURL(
                  FILES_LOCATION_COMPANY_LOGO,
                  jobDetails?.job_profile?.client_company_id
                    ? jobDetails.job_profile.client_company_id
                    : jobDetails?.job_profile?.company_id,
                  jobDetails?.company_profile?.logo?.extension,
                  jobDetails?.company_profile?.logo?.uploaded_at
                )}
                companyName={resolveMultiLingual(
                  jobDetails?.company_profile?.name
                )}
                companyWebsite={jobDetails?.company_profile?.website_url}
                agencyName={resolveMultiLingual(
                  jobDetails?.agency_profile?.name
                )}
                jobTitle={resolveMultiLingual(
                  jobDetails?.job_profile?.job_title
                )}
                location={jobDetails?.job_profile?.job_overview?.location}
                salary={jobDetails?.job_profile?.job_overview?.salary}
                requiredSkills={jobDetails?.job_profile?.skills}
                otherDetails={resolveMultiLingual(
                  jobDetails?.job_profile?.additional_information
                )}
                additionInfoFileDetails={jobAdditionalFileMetaData}
                cvFileRequirements={
                  jobDetails?.job_profile?.cv_file_requirements
                }
              />
            )}
          </Stack>
        </Stack>
      </Paper>
      <br />
      <Stack direction="row" justifyContent="space-between">
        {isJobDataLoading ? (
          <>
            <SkeletonButton />
            <SkeletonButton />
          </>
        ) : (
          <>
            <Button
              data-testid="job_additional_information_back_button"
              variant="outlined"
              startAdornment={<NavigateBeforeIcon />}
              handleClick={() =>
                navigate(
                  `/${translate.getCurrentLocale()}/employers/jobs/${jobId}/hiring-process`
                )
              }>
              {intl.get("t_general_back")}
            </Button>
            <Button
              data-testid="job_additional_information_finish_button"
              handleClick={() => {
                if (jobId) {
                  handleSetJobData(
                    jobId,
                    { ...jobData, updated_at: Timestamp.now() },
                    handleJobUpdateSuccess,
                    handleJobUpdateFail
                  );
                }
              }}
              loading={isDisabled}
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              endAdornment={<NavigateNextIcon />}>
              {intl.get("t_general_finish")}
            </Button>
          </>
        )}
      </Stack>
    </>
  );
};

export default Confirmation;

import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import CertificationForm from "@components/ResumeForms/CertificationForm";

import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import UserCertification from "@interfaces/database/UserCertification";

import { LOCALE, LOCALE_SHORT, MAX_CERTIFICATIONS } from "@utils/config";
import { prepareMultiLingual, resolveMultiLingual } from "@utils/multiLingual";
import Timestamp from "@utils/Timestamp";
import translate, { intl } from "@utils/translate";

interface CertificationFormData {
  name?: string;
  noExpiry?: Array<number>;
  issueDate?: Date | string;
  expiryDate?: Date | string;
  issuingOrganization: string;
  certificationUrl?: string;
  description?: string;
}

const EnglishResumeEditCertification = () => {
  const userData = useUserProfile();
  const navigate = useNavigate();
  const toast = useToast();
  const { item_id } = useParams();
  const itemId = Number(item_id);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  //Fetch the certification data based on valid itemId
  const userCertifications = userData.value?.cv?.certification ?? [];
  const certification = itemId >= 0 ? userCertifications?.[itemId] : undefined;

  //If the itemId is not valid, re-route to Add Form Page
  useEffect(() => {
    if (item_id && !certification) {
      navigate(
        `/${translate.getCurrentLocale()}/profile/resume/en-cv/edit/certification`,
        { replace: true }
      );
    }
  }, []);

  //Setting initial form values
  const initialValues = {
    name: resolveMultiLingual(certification?.certificate_name, LOCALE.EN) ?? "",
    noExpiry: !certification || certification?.timeline?.expiry ? [] : [1],
    issueDate: certification?.timeline?.issue?.toDate() ?? "",
    expiryDate: certification?.timeline?.expiry?.toDate() ?? "",
    issuingOrganization:
      resolveMultiLingual(certification?.issuer_name, LOCALE.EN) ?? "",
    certificationUrl:
      resolveMultiLingual(certification?.certificate_url, LOCALE.EN) ?? "",
    description:
      resolveMultiLingual(certification?.description, LOCALE.EN) ?? ""
  };

  const handleCertificationSavedSuccess = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_success_resume_saved"), "success");
  };

  const handleCertificationSavedFail = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleAdd = async (formData: CertificationFormData) => {
    setIsDisabled(true);
    const {
      name,
      issueDate,
      expiryDate,
      issuingOrganization,
      certificationUrl,
      description
    } = formData || {};

    const certification: UserCertification = {
      certificate_name: prepareMultiLingual(name ?? "", LOCALE.EN),
      issuer_name: prepareMultiLingual(issuingOrganization ?? "", LOCALE.EN),
      certificate_url: prepareMultiLingual(certificationUrl ?? "", LOCALE.EN),
      description: prepareMultiLingual(description ?? "", LOCALE.EN),
      timeline: {
        issue: Timestamp.fromDate(issueDate as Date)
      }
    };
    if (expiryDate) {
      certification.timeline.expiry = Timestamp.fromDate(expiryDate as Date);
    }

    try {
      if (userData.setValue && userData.value) {
        if (!userData.value?.cv) {
          userData.value.cv = { updated_at: Timestamp.now() };
        }

        if (!userData.value?.cv?.certification) {
          userData.value.cv.certification = [];
        }

        if (userData.value.cv.certification.length >= MAX_CERTIFICATIONS) {
          toast.kampai(
            intl.get("t_toast_error_limit_exceed", {
              fieldName: intl.get("t_resumes_certification_title"),
              maxLimit: MAX_CERTIFICATIONS
            }),
            "error"
          );

          return;
        }

        await userData.setValue(
          {
            ...userData.value,
            cv: {
              ...userData.value?.cv,
              certification: [...userData.value.cv.certification, certification]
            },
            updated_at: Timestamp.now()
          },
          handleCertificationSavedSuccess,
          handleCertificationSavedFail
        );
        navigate(`/${translate.getCurrentLocale()}/profile/resume/en-cv`);
      }
    } catch (e) {
      handleCertificationSavedFail();
    }
  };

  const handleEdit = async (formData: CertificationFormData) => {
    setIsDisabled(true);
    const {
      name,
      issueDate,
      expiryDate,
      issuingOrganization,
      certificationUrl,
      description
    } = formData || {};

    const existingCertification = userCertifications[itemId];

    try {
      if (
        item_id &&
        userData.setValue &&
        userData.value &&
        userData.value?.cv?.certification &&
        existingCertification
      ) {
        const certification: UserCertification = {
          certificate_name: prepareMultiLingual(
            name ?? "",
            LOCALE.EN,
            existingCertification.certificate_name
          ),
          issuer_name: prepareMultiLingual(
            issuingOrganization ?? "",
            LOCALE.EN,
            existingCertification.issuer_name
          ),
          certificate_url: prepareMultiLingual(
            certificationUrl ?? "",
            LOCALE.EN,
            existingCertification.certificate_url
          ),
          description: prepareMultiLingual(
            description ?? "",
            LOCALE.EN,
            existingCertification.description
          ),
          timeline: {
            issue: Timestamp.fromDate(issueDate as Date)
          }
        };

        if (expiryDate) {
          certification.timeline.expiry = Timestamp.fromDate(
            expiryDate as Date
          );
        }

        userCertifications[itemId] = certification;

        await userData.setValue(
          {
            ...userData.value,
            cv: {
              ...userData.value?.cv,
              certification: userCertifications
            }
          },
          handleCertificationSavedSuccess,
          handleCertificationSavedFail
        );
        navigate(`/${translate.getCurrentLocale()}/profile/resume/en-cv`);
      }
    } catch (e) {
      handleCertificationSavedFail();
    }
  };

  return (
    <CertificationForm
      handleFormSubmit={item_id ? handleEdit : handleAdd}
      initialValues={initialValues}
      backBtnLink={`/${translate.getCurrentLocale()}/profile/resume/en-cv`}
      isDisabled={isDisabled}
      formLanguage={LOCALE_SHORT.EN}
    />
  );
};

export default EnglishResumeEditCertification;

import { useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  BorderColor as BorderColorIcon,
  Close as CloseIcon,
  CurrencyYen as CurrencyYenIcon,
  Edit as EditIcon,
  Forum as ForumIcon,
  LaptopMac as LaptopMacIcon,
  LocationOn as LocationOnIcon,
  Psychology as PsychologyIcon,
  RecordVoiceOver as RecordVoiceOverIcon,
  Schedule as ScheduleIcon,
  Search as SearchIcon
} from "@mui/icons-material";
import { Box, Grid, Link, Slide, Stack, styled } from "@mui/material";
import { logEvent } from "firebase/analytics";
import { doc, getDoc } from "firebase/firestore";
import * as yup from "yup";

import SkeletonKeyword from "@skeletons/SkeletonKeyword";

import AutoCompleteTextField from "@components/AutoCompleteTextField";
import Button from "@components/Button";
import Cursor from "@components/Cursor";
import Icon from "@components/Icon";
import NonTypeableSelect from "@components/NonTypeableSelect";
import Tag from "@components/Tag";
import TextField from "@components/TextField";
import TypeableSelect from "@components/TypeableSelect";
import Typography from "@components/Typography";

import { useOptions } from "@hooks/useOptions";
import useToast from "@hooks/useToast";

import KeyLabel from "@interfaces/components/KeyLabel";
import CompanyProfile from "@interfaces/database/CompanyProfile";

import {
  ANALYTICS_EVENT_TYPE,
  ANIMATION_TIMEOUT_MS,
  FIRESTORE_COLLECTIONS,
  JOB_CONTRACT_TYPE,
  JOB_CONTRACT_TYPE_T_LABELS,
  JOB_REMOTE_WORK_TYPE,
  JOB_REMOTE_WORK_TYPE_T_LABELS,
  JOB_SEARCH_SALARY_FILTER,
  JOB_SEARCH_SALARY_FILTER_T_LABELS,
  JOB_VISA_SPONSORSHIP_AVAILABLE,
  JOB_VISA_SPONSORSHIP_AVAILABLE_T_LABELS,
  LANGUAGE_PROFICIENCY,
  LANGUAGE_PROFICIENCY_T_LABELS,
  YEARS_OF_EXPERIENCE,
  YEARS_OF_EXPERIENCE_T_LABELS
} from "@utils/config";
import { analytics, db } from "@utils/firebase";
import {
  getLocationLabel,
  getLocationList
} from "@utils/keyLabelHandlers/location";
import { getSkillLabel, getSkillList } from "@utils/keyLabelHandlers/skill";
import { resolveMultiLingual } from "@utils/multiLingual";
import { colorPalette } from "@utils/theme";
import translate, { intl } from "@utils/translate";

interface SearchForm {
  keyword?: string;
  location?: KeyLabel | null;
  minSalary?: string;
  remoteAllowed?: Array<string>;
  contractType?: Array<string>;
  visaSponsorship?: string;
  minExperience?: string;
  jaLevel?: Array<string>;
  enLevel?: Array<string>;
  skills?: Array<KeyLabel>;
}

interface SearchQuery {
  label: string;
  key: string;
  query_key: string;
  query_value: string;
  icon?: string;
}

interface SearchHeaderProps {
  resetListScroll?: () => void;
}

const StyledClearAllFilterContainer = styled(Stack)(({ theme }) => {
  return {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      width: 115,
      right: -119,
      transform: "translateY(-45%)",
      top: "45%"
    }
  };
});

const SearchHeader = ({
  resetListScroll = () => {
    /*no-op*/
  }
}: SearchHeaderProps) => {
  const [searchParams] = useSearchParams();
  const [isSearchOpen, setSearchOpen] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [formData, setFormData] = useState<SearchForm>({});
  const [searchQueries, setSearchQueries] = useState<Array<SearchQuery>>([]);
  const containerRef = useRef<HTMLElement>(null);

  const toast = useToast();
  const navigate = useNavigate();

  const JOB_SEARCH_SALARY_FILTER_OPTIONS = useOptions(
    JOB_SEARCH_SALARY_FILTER,
    JOB_SEARCH_SALARY_FILTER_T_LABELS
  );

  const CONTRACT_TYPE_OPTIONS = useOptions(
    JOB_CONTRACT_TYPE,
    JOB_CONTRACT_TYPE_T_LABELS
  );

  const YEARS_OF_EXPERIENCE_OPTIONS = useOptions(
    YEARS_OF_EXPERIENCE,
    YEARS_OF_EXPERIENCE_T_LABELS
  );

  const JOB_REMOTE_OPTIONS = useOptions(
    JOB_REMOTE_WORK_TYPE,
    JOB_REMOTE_WORK_TYPE_T_LABELS
  );

  const JOB_VISA_SPONSORSHIP_AVAILABLE_OPTIONS = useOptions(
    JOB_VISA_SPONSORSHIP_AVAILABLE,
    JOB_VISA_SPONSORSHIP_AVAILABLE_T_LABELS
  );

  const LANGUAGE_PROFICIENCY_OPTIONS = useOptions(
    LANGUAGE_PROFICIENCY,
    LANGUAGE_PROFICIENCY_T_LABELS
  );

  const schema = yup.object().shape({
    keyword: yup.string(),
    location: yup
      .object()
      .shape({
        key: yup.string(),
        label: yup.string()
      })
      .nullable(),
    minSalary: yup.string(),
    remoteAllowed: yup
      .array()
      .of(yup.string())
      .typeError(intl.get("t_general_internal_error")),
    contractType: yup
      .array()
      .of(yup.string())
      .typeError(intl.get("t_general_internal_error")),
    visaSponsorship: yup.string(),
    minExperience: yup.string(),
    jaLevel: yup
      .array()
      .of(yup.string())
      .typeError(intl.get("t_general_internal_error")),
    enLevel: yup
      .array()
      .of(yup.string())
      .typeError(intl.get("t_general_internal_error")),
    skills: yup
      .array()
      .of(
        yup.object().shape({
          key: yup.string().trim(),
          label: yup.string().trim()
        })
      )
      .typeError(intl.get("t_general_internal_error"))
  });

  const methods = useForm({
    defaultValues: formData,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, setValue, reset } = methods;

  const prepareData = async () => {
    try {
      const defaultValues: SearchForm = {};
      const searchQueries: Array<SearchQuery> = [];

      const keyword = searchParams.get("q");
      if (keyword) {
        defaultValues.keyword = keyword;
        searchQueries.push({
          label: keyword,
          key: "keyword",
          query_key: "q",
          query_value: keyword,
          icon: "search"
        });
      }

      const location = searchParams.get("location");
      if (location) {
        const locationLabel = await getLocationLabel(location);
        defaultValues.location = {
          key: location,
          label: locationLabel
        };
        searchQueries.push({
          label: locationLabel,
          key: "location",
          query_key: "location",
          query_value: location,
          icon: "location_on"
        });
      } else {
        // Note: This is needed otherwise it becomes an uncontrolled component
        // https://react.dev/reference/react-dom/components/input#controlling-an-input-with-a-state-variable
        defaultValues.location = null;
      }

      const minSalary = searchParams.get("min_salary");
      if (
        minSalary &&
        (
          Object.keys(JOB_SEARCH_SALARY_FILTER_T_LABELS) as Array<string>
        ).includes(minSalary)
      ) {
        defaultValues.minSalary = minSalary;
        searchQueries.push({
          label: intl.get("t_job_search_filter_minimum_salary", {
            salary: intl.get(
              JOB_SEARCH_SALARY_FILTER_T_LABELS[
                minSalary as keyof typeof JOB_SEARCH_SALARY_FILTER_T_LABELS
              ]
            )
          }),
          key: "minSalary",
          query_key: "min_salary",
          query_value: minSalary,
          icon: "currency_yen"
        });
      }

      const remoteAllowed = searchParams.getAll("remote_allowed");
      if (remoteAllowed.length > 0) {
        const remoteAllowedValues: Array<string> = [];
        remoteAllowed.forEach((singleRemoteAllowed: string) => {
          if (
            (Object.values(JOB_REMOTE_WORK_TYPE) as Array<string>).includes(
              singleRemoteAllowed
            )
          ) {
            searchQueries.push({
              label: intl.get(
                JOB_REMOTE_WORK_TYPE_T_LABELS[
                  singleRemoteAllowed as keyof typeof JOB_REMOTE_WORK_TYPE_T_LABELS
                ]
              ),
              key: "remoteAllowed",
              query_key: "remote_allowed",
              query_value: singleRemoteAllowed,
              icon: "laptop_mac"
            });
          }
          remoteAllowedValues.push(singleRemoteAllowed);
        });
        defaultValues.remoteAllowed = remoteAllowedValues;
      }

      const contractType = searchParams.getAll("contract_type");
      if (contractType.length > 0) {
        const contractTypeValues: Array<string> = [];
        contractType.forEach((singleContractType: string) => {
          if (
            (Object.values(JOB_CONTRACT_TYPE) as Array<string>).includes(
              singleContractType
            )
          ) {
            searchQueries.push({
              label: intl.get(
                JOB_CONTRACT_TYPE_T_LABELS[
                  singleContractType as keyof typeof JOB_CONTRACT_TYPE_T_LABELS
                ]
              ),
              key: "contractType",
              query_key: "contract_type",
              query_value: singleContractType,
              icon: "schedule"
            });
            contractTypeValues.push(singleContractType);
          }
        });
        defaultValues.contractType = contractTypeValues;
      }

      const visaSponsorship = searchParams.get("visa_sponsorship");
      if (
        visaSponsorship &&
        (
          Object.keys(JOB_VISA_SPONSORSHIP_AVAILABLE_T_LABELS) as Array<string>
        ).includes(visaSponsorship)
      ) {
        defaultValues.visaSponsorship = visaSponsorship;
        searchQueries.push({
          label: intl.get("t_job_search_filter_visa_sponsored", {
            visa_sponsorship: intl.get(
              JOB_VISA_SPONSORSHIP_AVAILABLE_T_LABELS[
                visaSponsorship as keyof typeof JOB_VISA_SPONSORSHIP_AVAILABLE_T_LABELS
              ]
            )
          }),
          key: "visaSponsorship",
          query_key: "visa_sponsorship",
          query_value: visaSponsorship,
          icon: "edit_document"
        });
      }

      const minExperience = searchParams.get("min_experience");
      if (
        minExperience &&
        (Object.keys(YEARS_OF_EXPERIENCE_T_LABELS) as Array<string>).includes(
          minExperience
        )
      ) {
        defaultValues.minExperience = minExperience;
        searchQueries.push({
          label: intl.get("t_job_search_filter_minimum_experience", {
            experience: intl.get(
              YEARS_OF_EXPERIENCE_T_LABELS[
                minExperience as keyof typeof YEARS_OF_EXPERIENCE_T_LABELS
              ]
            )
          }),
          key: "minExperience",
          query_key: "min_experience",
          query_value: minExperience,
          icon: "psychology"
        });
      }

      const jaLevel = searchParams.getAll("language_ja_level");
      if (jaLevel && jaLevel.length > 0) {
        const jaLevelValues: Array<string> = [];
        jaLevel.forEach((singleJaLevel: string) => {
          if (
            (Object.values(LANGUAGE_PROFICIENCY) as Array<string>).includes(
              singleJaLevel
            )
          ) {
            searchQueries.push({
              label: intl.get("t_search_filter_japanese_level", {
                level: intl.get(
                  LANGUAGE_PROFICIENCY_T_LABELS[
                    singleJaLevel as keyof typeof LANGUAGE_PROFICIENCY_T_LABELS
                  ]
                )
              }),
              key: "jaLevel",
              query_key: "language_ja_level",
              query_value: singleJaLevel,
              icon: "record_voice_over"
            });
            jaLevelValues.push(singleJaLevel);
          }
        });
        defaultValues.jaLevel = jaLevelValues;
      }

      const enLevel = searchParams.getAll("language_en_level");
      if (enLevel && enLevel.length > 0) {
        const enLevelValues: Array<string> = [];
        enLevel.forEach((singleEnLevel: string) => {
          if (
            (Object.values(LANGUAGE_PROFICIENCY) as Array<string>).includes(
              singleEnLevel
            )
          ) {
            searchQueries.push({
              label: intl.get("t_search_filter_english_level", {
                level: intl.get(
                  LANGUAGE_PROFICIENCY_T_LABELS[
                    singleEnLevel as keyof typeof LANGUAGE_PROFICIENCY_T_LABELS
                  ]
                )
              }),
              key: "enLevel",
              query_key: "language_en_level",
              query_value: singleEnLevel,
              icon: "forum"
            });
            enLevelValues.push(singleEnLevel);
          }
        });
        defaultValues.enLevel = enLevelValues;
      }

      const skills = searchParams.getAll("skills");
      if (skills.length > 0) {
        const defaultValueSkills: Array<KeyLabel> = [];
        for (const singleSkill of skills) {
          const skillLabel = await getSkillLabel(
            singleSkill,
            translate.getCurrentLocale()
          );
          defaultValueSkills.push({
            key: singleSkill,
            label: skillLabel
          });
          searchQueries.push({
            label: skillLabel,
            key: "skill",
            query_key: "skills",
            query_value: singleSkill,
            icon: "work"
          });
        }
        defaultValues.skills = defaultValueSkills;
      }

      const companyId = searchParams.get("company_id");
      if (companyId) {
        const companyRef = doc(db, FIRESTORE_COLLECTIONS.COMPANIES, companyId);
        const companySnap = await getDoc(companyRef);
        if (companySnap.exists()) {
          const companyData = companySnap.data() as CompanyProfile;
          const companyName = resolveMultiLingual(companyData.name);
          searchQueries.push({
            label: companyName ?? "",
            key: "companyId",
            query_key: "company_id",
            query_value: companyId,
            icon: "domain"
          });
        }
      }

      setFormData(defaultValues);
      setSearchQueries(searchQueries);
      reset(defaultValues);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.kampai(intl.get("t_general_internal_error"), "error");
    }
  };

  useEffect(() => {
    (async () => {
      await prepareData();
    })();
  }, [translate.getCurrentLocale()]);

  useEffect(() => {
    (async () => {
      setSearchOpen(false);
      await prepareData();
    })();
  }, [searchParams]);

  const handleSearch = (formData: SearchForm) => {
    // Create an object to store event parameters
    const eventParams: Record<string, string> = {};

    if (formData.keyword) {
      searchParams.set("q", formData.keyword);
    } else {
      searchParams.delete("q");
    }

    if (formData.location?.key) {
      searchParams.set("location", formData.location.key);
    } else {
      searchParams.delete("location");
    }

    if (formData.minSalary) {
      searchParams.set("min_salary", formData.minSalary);
      eventParams.min_salary = formData.minSalary;
    } else {
      searchParams.delete("min_salary");
    }

    searchParams.delete("remote_allowed");
    if (formData.remoteAllowed) {
      for (const singleRemoteAllowed of formData.remoteAllowed) {
        searchParams.append("remote_allowed", singleRemoteAllowed);
      }
    }

    searchParams.delete("language_ja_level");
    if (formData.jaLevel) {
      for (const singleJaLevel of formData.jaLevel) {
        searchParams.append("language_ja_level", singleJaLevel);
      }
    }

    searchParams.delete("language_en_level");
    if (formData.enLevel) {
      for (const singleEnLevel of formData.enLevel) {
        searchParams.append("language_en_level", singleEnLevel);
      }
    }

    searchParams.delete("contract_type");
    if (formData.contractType) {
      for (const singleContractType of formData.contractType) {
        searchParams.append("contract_type", singleContractType);
      }
    }

    if (formData.visaSponsorship) {
      searchParams.set("visa_sponsorship", formData.visaSponsorship);
      eventParams.visa_sponsorship = formData.visaSponsorship;
    } else {
      searchParams.delete("visa_sponsorship");
    }

    if (formData.minExperience) {
      searchParams.set("min_experience", formData.minExperience);
      eventParams.min_experience = formData.minExperience;
    } else {
      searchParams.delete("min_experience");
    }

    searchParams.delete("skills");
    if (Array.isArray(formData.skills) && formData.skills?.length > 0) {
      for (const singleSkill of formData.skills) {
        searchParams.append("skills", singleSkill.key);
      }
    }

    logEvent(analytics, ANALYTICS_EVENT_TYPE.SEARCH_JOB, eventParams);

    searchParams.delete("hide_search");
    searchParams.set("page", "1");

    setSearchOpen(false);
    let url = `/${translate.getCurrentLocale()}/search`;
    if (searchParams.toString()) {
      url += `?${searchParams.toString()}`;
    }
    navigate(url);
    setIsLoading(true);
    prepareData();
    resetListScroll();
  };

  const removeSearchQuery = (searchQuery: SearchQuery) => {
    if (
      searchQuery.query_key === "skills" ||
      searchQuery.query_key === "remote_allowed" ||
      searchQuery.query_key === "language_ja_level" ||
      searchQuery.query_key === "language_en_level" ||
      searchQuery.query_key === "contract_type"
    ) {
      const remainingValues = searchParams
        .getAll(searchQuery.query_key)
        .filter(
          (singleQueryValue: string) =>
            singleQueryValue !== searchQuery.query_value
        );

      searchParams.delete(searchQuery.query_key);
      remainingValues.forEach((singleRemainingValue: string) => {
        searchParams.append(searchQuery.query_key, singleRemainingValue);
      });

      if (remainingValues.length === 0) {
        delete formData[searchQuery.key as keyof SearchForm];
      }
      if (searchQuery.query_key === "skills") {
        const remainingSkills = formData.skills?.filter(
          (singleSkill: KeyLabel) => singleSkill.key !== searchQuery.query_value
        );
        formData.skills = remainingSkills;
      } else if (searchQuery.query_key === "remote_allowed") {
        const remainingRemoteAllowed = formData.remoteAllowed?.filter(
          (singleRemoteAllowed: string) =>
            singleRemoteAllowed !== searchQuery.query_value
        );
        formData.remoteAllowed = remainingRemoteAllowed;
      } else if (searchQuery.query_key === "language_ja_level") {
        const remainingJaLevel = formData.jaLevel?.filter(
          (singleJaLevel: string) => singleJaLevel !== searchQuery.query_value
        );
        formData.jaLevel = remainingJaLevel;
      } else if (searchQuery.query_key === "language_en_level") {
        const remainingEnLevel = formData.enLevel?.filter(
          (singleEnLevel: string) => singleEnLevel !== searchQuery.query_value
        );
        formData.enLevel = remainingEnLevel;
      } else if (searchQuery.query_key === "contract_type") {
        const remainingContractType = formData.contractType?.filter(
          (singleContractType: string) =>
            singleContractType !== searchQuery.query_value
        );
        formData.contractType = remainingContractType;
      }
    } else {
      searchParams.delete(searchQuery.query_key);
      delete formData[searchQuery.key as keyof SearchForm];
    }

    const filteredSearchQueries = searchQueries.filter(
      (singleSearchQuery: SearchQuery) =>
        singleSearchQuery.query_value !== searchQuery.query_value ||
        singleSearchQuery.query_key !== searchQuery.query_key
    );

    searchParams.set("page", "1");
    setFormData(formData);
    setSearchQueries(filteredSearchQueries);
    reset(formData);
    let url = `/${translate.getCurrentLocale()}/search`;
    if (searchParams.toString()) {
      url += `?${searchParams.toString()}`;
    }
    navigate(url);
    resetListScroll();
  };

  /* FIXME: if a new filter is added in the future, 
  then clear all function needs to change 
  as it explicitly needs which filters to remove. 
  (It doesn't remove all the query parameters)*/
  const clearAllFilters = () => {
    searchParams.delete("q");
    searchParams.delete("location");
    searchParams.delete("min_salary");
    searchParams.delete("remote_allowed");
    searchParams.delete("contract_type");
    searchParams.delete("visa_sponsorship");
    searchParams.delete("min_experience");
    searchParams.delete("language_ja_level");
    searchParams.delete("language_en_level");
    searchParams.delete("skills");

    formData.keyword = "";
    formData.location = { key: "", label: "" };
    formData.minSalary = "";
    formData.remoteAllowed = [];
    formData.contractType = [];
    formData.visaSponsorship = "";
    formData.minExperience = "";
    formData.jaLevel = [];
    formData.enLevel = [];
    formData.skills = [];

    setSearchOpen(false);
    let url = `/${translate.getCurrentLocale()}/search`;
    if (searchParams.toString()) {
      url += `?${searchParams.toString()}`;
    }
    navigate(url);
    setIsLoading(true);
    prepareData();
    resetListScroll();
  };

  const cancelSearch = () => {
    reset(formData);
    setSearchOpen(false);
  };

  return (
    <Box
      py={2}
      px={4}
      borderRadius={1}
      bgcolor="common.white"
      ref={containerRef}>
      {/* FIXME: Fix the Typography here */}
      <Typography
        variant="subtitle2"
        fontWeight="bold"
        mr={1}
        sx={{ verticalAlign: "middle" }}>
        <Icon type="filter_alt" filled />
      </Typography>

      {/* For the Title */}
      <Typography variant="subtitle2" fontWeight="bold">
        {isSearchOpen
          ? intl.get("t_job_search_filter_by")
          : intl.get("t_job_search_filter_results")}
      </Typography>

      {/* For the Icon */}
      {!isSearchOpen ? (
        <Box
          onClick={() => {
            setSearchOpen(true);
          }}
          sx={{ float: "right" }}>
          <Cursor>
            <BorderColorIcon
              sx={{
                "color": colorPalette.black.base,
                "&:hover": {
                  color: colorPalette.blue.base
                }
              }}
            />
          </Cursor>
        </Box>
      ) : (
        false
      )}

      {/* For the Search Form */}
      <Box
        mt={2}
        position="relative"
        mr={{ md: !isSearchOpen ? 15 : "unset" }}
        overflow="hidden">
        {isSearchOpen ? (
          <Slide
            container={containerRef.current}
            in={isSearchOpen}
            direction="down"
            {...(isSearchOpen ? { timeout: ANIMATION_TIMEOUT_MS.FAST } : {})}>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(handleSearch)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  {/* NOTE: In future, this should be an autosuggestbox based on the company names and job titles we have */}
                  <TextField
                    name="keyword"
                    control={control}
                    placeholder={intl.get("t_job_search_placeholder_keyword")}
                    sx={{ textTransform: "capitalize" }}
                    startAdornment={<SearchIcon height={24} width={24} />}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AutoCompleteTextField
                    name="location"
                    control={control}
                    setValue={setValue}
                    placeholder={intl.get("t_job_search_placeholder_location")}
                    startAdornment={<LocationOnIcon height={24} width={24} />}
                    getOptions={getLocationList}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <NonTypeableSelect
                    setValue={setValue}
                    control={control}
                    name="minExperience"
                    label={intl.get("t_job_search_placeholder_experience")}
                    placeholder={intl.get(
                      "t_job_search_placeholder_experience"
                    )}
                    startAdornment={<PsychologyIcon height={24} width={24} />}
                    options={YEARS_OF_EXPERIENCE_OPTIONS}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                  {/* FIXME: Fetch from Skill function after shubham's implementation. */}
                  <TypeableSelect
                    control={control}
                    name="skills"
                    multiple
                    label={intl.get("t_general_skills")}
                    placeholder={intl.get("t_general_skills")}
                    options={[]}
                    getOptions={getSkillList}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <NonTypeableSelect
                    setValue={setValue}
                    control={control}
                    name="minSalary"
                    label={intl.get("t_general_salary")}
                    placeholder={intl.get("t_general_salary")}
                    startAdornment={<CurrencyYenIcon height={24} width={24} />}
                    options={JOB_SEARCH_SALARY_FILTER_OPTIONS}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <NonTypeableSelect
                    multiSelect
                    setValue={setValue}
                    control={control}
                    name="remoteAllowed"
                    label={intl.get("t_general_remote_work")}
                    placeholder={intl.get("t_general_remote_work")}
                    startAdornment={<LaptopMacIcon height={24} width={24} />}
                    options={JOB_REMOTE_OPTIONS}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <NonTypeableSelect
                    multiSelect
                    setValue={setValue}
                    control={control}
                    name="enLevel"
                    label={intl.get("t_job_search_placeholder_english_level")}
                    placeholder={intl.get(
                      "t_job_search_placeholder_english_level"
                    )}
                    startAdornment={<ForumIcon height={24} width={24} />}
                    options={LANGUAGE_PROFICIENCY_OPTIONS}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <NonTypeableSelect
                    multiSelect
                    setValue={setValue}
                    control={control}
                    name="jaLevel"
                    label={intl.get("t_job_search_placeholder_japanese_level")}
                    placeholder={intl.get(
                      "t_job_search_placeholder_japanese_level"
                    )}
                    startAdornment={
                      <RecordVoiceOverIcon height={24} width={24} />
                    }
                    options={LANGUAGE_PROFICIENCY_OPTIONS}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <NonTypeableSelect
                    multiSelect
                    setValue={setValue}
                    control={control}
                    name="contractType"
                    label={intl.get(
                      "t_resumes_job_detail_form_label_contract_type"
                    )}
                    placeholder={intl.get(
                      "t_resumes_job_detail_form_label_contract_type"
                    )}
                    startAdornment={<ScheduleIcon height={24} width={24} />}
                    options={CONTRACT_TYPE_OPTIONS}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <NonTypeableSelect
                    setValue={setValue}
                    control={control}
                    name="visaSponsorship"
                    label={intl.get("t_job_visa_sponsorship")}
                    placeholder={intl.get("t_job_visa_sponsorship")}
                    startAdornment={<EditIcon height={24} width={24} />}
                    options={JOB_VISA_SPONSORSHIP_AVAILABLE_OPTIONS}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row-reverse" spacing={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isLoading}>
                      {intl.get("t_general_view_results")}
                    </Button>
                    <Button
                      variant="outlined"
                      handleClick={cancelSearch}
                      type="reset">
                      {intl.get("t_general_cancel")}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Slide>
        ) : (
          <>
            {isLoading ? (
              <SkeletonKeyword multiple />
            ) : (
              <>
                <Box
                  display={{ md: "flex" }}
                  overflow={{ md: "auto" }}
                  whiteSpace={{ md: "nowrap" }}>
                  <Stack
                    direction="row"
                    gap={1}
                    overflow="auto"
                    flexShrink={0}
                    whiteSpace="nowrap">
                    {searchQueries.map(
                      (singleSearchQuery: SearchQuery, index: number) => (
                        <Tag
                          key={index}
                          label={singleSearchQuery.label}
                          data-testid="search_query_list_item"
                          startAdornment={
                            singleSearchQuery.icon ? (
                              <Typography variant="subtitle3">
                                <Icon type={singleSearchQuery.icon} filled />
                              </Typography>
                            ) : (
                              false
                            )
                          }
                          endAdornment={
                            <Box
                              component="span"
                              onClick={() =>
                                removeSearchQuery(singleSearchQuery)
                              }>
                              <Cursor>
                                <CloseIcon fontSize="small" />
                              </Cursor>
                            </Box>
                          }
                        />
                      )
                    )}
                  </Stack>
                </Box>
                {searchQueries.length > 0 ? (
                  <StyledClearAllFilterContainer
                    flexDirection="row"
                    justifyContent="flex-end"
                    ml={1}
                    mt={{ xs: 1, md: "unset" }}>
                    <Link
                      component="button"
                      variant="subtitle5"
                      onClick={() => {
                        clearAllFilters();
                      }}>
                      {intl.get("t_search_header_clear_all_filters")}
                    </Link>
                  </StyledClearAllFilterContainer>
                ) : (
                  false
                )}
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default SearchHeader;

import { ReactNode, useEffect, useState } from "react";

import { Box, CircularProgress, Button as MuiButton } from "@mui/material";

import { colorPalette } from "@utils/theme";

interface ButtonProps {
  size?: "small" | "medium" | "large";
  color?: "primary" | "secondary" | "error";
  variant?: "contained" | "outlined" | "text" | "light";
  children?: ReactNode;
  component?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  type?: "submit" | "reset";
  fullWidth?: boolean;
  disabled?: boolean;
  handleClick?: () => void;
  loading?: boolean; // This defines the loading state to be controlled by the parent.
  isLoadable?: boolean; // This tells whether the button is loadable or not.
  formId?: string;
}

const Button = ({
  size = "medium",
  color = "primary",
  variant = "contained",
  disabled = false,
  startAdornment = null,
  endAdornment = null,
  loading = false,
  isLoadable = false,
  children = "",
  handleClick = () => {
    /* no-op */
  },
  formId,
  ...rest
}: ButtonProps) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setLoading(loading);
  }, [loading]);
  return (
    <>
      <MuiButton
        size={size}
        variant={variant}
        color={color}
        disabled={disabled}
        startIcon={startAdornment}
        endIcon={endAdornment}
        onClick={() => {
          isLoadable ? setLoading(true) : false;
          if (!loading && !isLoading) {
            handleClick();
          }
        }}
        sx={{
          position: "relative"
        }}
        form={formId}
        {...rest}>
        <Box visibility={isLoading ? "hidden" : "visible"}>{children}</Box>
        {isLoading ? (
          <CircularProgress
            size={size === "large" ? 23 : size === "medium" ? 21 : 20}
            color="inherit"
            sx={{
              position: "absolute",
              color:
                variant === "light"
                  ? colorPalette.blue.base
                  : variant === "text" || variant === "outlined"
                  ? color === "primary"
                    ? colorPalette.blue.base
                    : colorPalette.orange.base
                  : colorPalette.white.base
            }}
          />
        ) : (
          false
        )}
      </MuiButton>
    </>
  );
};

export default Button;

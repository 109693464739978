import { MouseEvent, useEffect, useRef, useState } from "react";

import {
  Link,
  matchPath,
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams
} from "react-router-dom";

import {
  ChatRounded as ChatRoundedIcon,
  MenuRounded as MenuRoundedIcon,
  Notifications as NotificationsIcon,
  PersonRounded as PersonRoundedIcon
} from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Link as MuiLink,
  Popover,
  Stack,
  styled,
  Toolbar,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

import CandidateNotifications from "@pages/CandidateNotifications";
import EmployerNotifications from "@pages/EmployerNotifications";

import HamburgerMenu from "@components/Header/HamburgerMenu";
import HeaderBackground from "@components/Header/HeaderBackground";
import LanguageSwitcher from "@components/Header/LanguageSwitcher";
import ProfileMenu from "@components/Header/ProfileMenu";
import SignInSignUpDialog from "@components/Header/SignInSignUpDialog";
import Typography from "@components/Typography";
import VerificationEmailSentDialog from "@components/VerificationEmailSentDialog";

import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import EnvironmentSpecific from "@utils/components/EnvironmentSpecific";
import {
  ENVIRONMENT,
  FIRESTORE_COLLECTIONS,
  HIDE_LANGUAGE_SWITCHER_PATHS,
  LOCALE,
  NOTIFICATION_STATUS,
  UNREAD_NOTIFICATION_COUNT_BADGE_MAX_LIMIT,
  USER_TYPE
} from "@utils/config";
import { auth, db } from "@utils/firebase";
import theme, { colorPalette } from "@utils/theme";
import translate, { intl } from "@utils/translate";

import Logo from "@assets/images/Logo.png";
import Logo_White from "@assets/images/Logo_White.png";

export interface HeaderProps {
  variant?: "primary" | "secondary";
  isLoggedIn: boolean;
  isSticky: boolean;
}

interface HeaderLinkProps {
  "to": string;
  "title": string;
  "target"?: "_self" | "_blank";
  "variant"?: HeaderProps["variant"];
  "onClick"?: () => void;
  "data-testid"?: string;
}

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("md")]: {
    height: 64
  },
  [theme.breakpoints.up("md")]: {
    height: 100
  }
}));

const StyledLogo = styled(Avatar)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    height: 52,
    width: 52
  },
  [theme.breakpoints.up("md")]: {
    height: 72,
    width: 72
  },
  "& .MuiAvatar-img": {
    objectFit: "contain"
  }
}));

const StyledIconButton = styled(IconButton)<{
  variant: HeaderProps["variant"];
}>(({ theme, variant }) => ({
  "height": 32,
  "width": 32,
  "color":
    variant === "primary" ? theme.palette.text.primary : colorPalette.white[80],
  ":hover": {
    color:
      variant === "primary"
        ? theme.palette.primary.main
        : theme.palette.common.white,
    backgroundColor: "transparent"
  },
  "cursor": "pointer"
}));

const HeaderLink = ({
  to,
  title,
  variant = "primary",
  target = "_self",
  onClick,
  "data-testid": dataTestId
}: HeaderLinkProps) => {
  return (
    <MuiLink
      underline="none"
      component={NavLink}
      to={to}
      target={target}
      rel={target === "_blank" ? "noopener noreferrer" : ""}
      onClick={onClick}
      data-testid={dataTestId}
      sx={{
        "color":
          variant === "primary"
            ? theme.palette.text.primary
            : colorPalette.white[80],
        ":hover, &.active": {
          color:
            variant === "primary"
              ? theme.palette.primary.main
              : theme.palette.common.white
        }
      }}>
      <Typography variant="h5">{title}</Typography>
    </MuiLink>
  );
};

const Header = ({
  variant = "primary",
  isLoggedIn,
  isSticky = false
}: HeaderProps) => {
  const matches = useMediaQuery(useTheme().breakpoints.up("md"));
  const menuElement = useRef<HTMLButtonElement>(null);

  const [user] = useAuthState(auth);
  const userId = user?.uid ?? "";
  const navigate = useNavigate();
  const userProfile = useUserProfile();
  const { progress_flags } = userProfile?.value ?? {};
  const userType = userProfile?.value?.user_type;
  const isNoType = userType === USER_TYPE.NO_TYPE;
  const isEmployer =
    userType === USER_TYPE.COMPANY || userType === USER_TYPE.COMPANY_MANAGER;

  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openProfileMenu, setOpenProfileMenu] = useState<boolean>(false);
  const [totalNotificationCount, setTotalNotificationCount] =
    useState<number>(0);
  const [anchorNotificationEl, setAnchorNotificationEl] =
    useState<HTMLElement | null>(null);
  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);
  const toast = useToast();

  const [searchParams] = useSearchParams();
  const currentLocale = translate.getCurrentLocale();

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const { pathname: originalPathname } = useLocation();
  const pathname = translate.getNonLocalizedURL(originalPathname);

  const hideLanguageSwitcher = HIDE_LANGUAGE_SWITCHER_PATHS.some(
    (singlePath) => {
      return matchPath(singlePath, pathname);
    }
  );

  const isProfileNotCompleted =
    !progress_flags?.basic_information ||
    !progress_flags?.job_experience_overview ||
    !progress_flags?.language ||
    !progress_flags?.skills;

  useEffect(() => {
    if (matches) {
      setOpenMenu(false);
    }
  }, [matches]);

  useEffect(() => {
    try {
      const notificationRef = collection(
        db,
        FIRESTORE_COLLECTIONS.NOTIFICATIONS
      );
      const q = query(
        notificationRef,
        where("userId", "==", userId),
        where("status", "==", NOTIFICATION_STATUS.UNREAD)
      );
      const unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          setTotalNotificationCount(snapshot.size);
        },
        () => {
          toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
        }
      );
      return () => unsubscribe();
    } catch (err) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  }, [userId]);

  const handleNotificationPopUp = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorNotificationEl(event.currentTarget);
    setIsNotificationOpen(true);
  };

  const handleNotificationPopUpClose = () => {
    setAnchorNotificationEl(null);
    setIsNotificationOpen(false);
  };

  return (
    <>
      {isLoggedIn &&
      searchParams.has("send_verification_email") &&
      searchParams.get("send_verification_email") ? (
        <VerificationEmailSentDialog />
      ) : (
        false
      )}
      {variant === "secondary" ? <HeaderBackground /> : false}
      <AppBar
        position={isSticky ? "sticky" : "relative"}
        elevation={variant === "primary" ? 4 : 0}
        sx={{
          backgroundColor:
            variant === "primary"
              ? theme.palette.background.default
              : "transparent",
          zIndex: 5,
          width: openProfileMenu ? "100vw" : "unset",
          paddingRight: openProfileMenu ? "calc(100vw - 100%)" : "unset",
          scrollbarGutter: "stable"
        }}>
        <StyledToolbar>
          <MuiLink component={Link} to={`/${translate.getCurrentLocale()}/`}>
            <StyledLogo
              data-testid="header_tokhimo_image"
              variant="square"
              src={variant === "primary" ? Logo : Logo_White}
              alt={intl.get("t_alt_tokhimo_job_logo")}
            />
          </MuiLink>

          <Stack direction="row" alignItems="center" spacing={1.5} ml="auto">
            {/* Header Links start - Desktop */}
            <Stack
              display={{ xs: "none", md: "flex" }}
              direction="row"
              spacing={3}>
              {isEmployer ? (
                <>
                  <HeaderLink
                    data-testid="header_nav_employer_dashboard_link"
                    variant={variant}
                    title={intl.get("t_header_navlink_dashboard")}
                    to={`/${translate.getCurrentLocale()}/employers/dashboard`}
                  />
                  <HeaderLink
                    variant={variant}
                    title={intl.get("t_header_navlink_search_candidates")}
                    to={`/${translate.getCurrentLocale()}/employers/search`}
                  />
                </>
              ) : (
                <>
                  <HeaderLink
                    data-testid="header_find_jobs_link"
                    variant={variant}
                    title={intl.get("t_general_find_jobs")}
                    to={`/${translate.getCurrentLocale()}/search`}
                  />
                  {!isLoggedIn ? (
                    <HeaderLink
                      data-testid="header_employers_link"
                      variant={variant}
                      title={intl.get("t_header_navlink_employers")}
                      to={`/${translate.getCurrentLocale()}/employers`}
                    />
                  ) : (
                    false
                  )}
                </>
              )}
            </Stack>
            {/* Header Links end - Desktop */}

            {/* Language Switcher start - Desktop */}
            {!hideLanguageSwitcher ? (
              <Box
                display={{ xs: "none", md: "block" }}
                data-testid="header_language_switcher_box">
                <LanguageSwitcher variant={variant} />
              </Box>
            ) : (
              false
            )}
            {/* Language Switcher end - Desktop */}

            <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
              <Box display={{ xs: "none", md: isLoggedIn ? "block" : "none" }}>
                <StyledIconButton
                  data-testid="header_conversations_link"
                  variant={variant}
                  onClick={() =>
                    navigate(
                      isEmployer
                        ? `/${translate.getCurrentLocale()}/employers/conversations`
                        : `/${translate.getCurrentLocale()}/conversations`
                    )
                  }>
                  <ChatRoundedIcon />
                </StyledIconButton>
              </Box>
            </EnvironmentSpecific>

            {/* Notifications start - Desktop */}
            <Box display={{ xs: "none", md: isLoggedIn ? "block" : "none" }}>
              <StyledIconButton
                variant={variant}
                onClick={(e) => handleNotificationPopUp(e)}>
                <Badge
                  max={UNREAD_NOTIFICATION_COUNT_BADGE_MAX_LIMIT}
                  color="secondary"
                  badgeContent={totalNotificationCount}>
                  <NotificationsIcon />
                </Badge>
              </StyledIconButton>
            </Box>
            {/* Notifications end - Desktop */}

            <Stack direction="row" alignItems="center" spacing={1.5}>
              {userType && !isNoType && isLoggedIn ? (
                <Stack
                  direction="row"
                  display={{ xs: "none", md: "block" }}
                  alignItems="center"
                  spacing={1.5}>
                  {/* Profile Menu start - Desktop*/}
                  <StyledIconButton
                    variant={variant}
                    ref={menuElement}
                    onClick={() => setOpenProfileMenu(!openProfileMenu)}
                    disableRipple
                    aria-label="profile menu">
                    <PersonRoundedIcon />
                  </StyledIconButton>
                  {/* Profile Menu end - Desktop */}

                  {userType && !isNoType && !isEmployer ? (
                    <Button
                      data-testid="candidate_update_profile_resume_button"
                      id="candidate_update_profile_resume_button"
                      variant="contained"
                      color={variant}
                      size={matches ? "large" : "medium"}
                      onClick={() =>
                        navigate(
                          "/" +
                            translate.getCurrentLocale() +
                            "/" +
                            isProfileNotCompleted
                            ? "profile"
                            : currentLocale === LOCALE.EN
                            ? "profile/resume/en-cv"
                            : "profile/resume/ja-cv"
                        )
                      }>
                      {intl.get(
                        isProfileNotCompleted
                          ? "t_general_update_profile"
                          : "t_general_update_resume"
                      )}
                    </Button>
                  ) : (
                    false
                  )}
                </Stack>
              ) : (
                <SignInSignUpDialog
                  initiator={
                    <Button
                      data-testid="sign_in_sign_up_dialog_open_button"
                      variant="contained"
                      color={variant}
                      size={matches ? "large" : "medium"}>
                      {intl.get("t_general_signin")}
                    </Button>
                  }
                />
              )}

              {/* Hamburger Menu start - Mobile*/}
              <Box display={{ xs: "block", md: "none" }}>
                <StyledIconButton
                  variant={variant}
                  disableRipple
                  aria-label="menu"
                  onClick={() => setOpenMenu(!openMenu)}>
                  <MenuRoundedIcon />
                </StyledIconButton>
              </Box>
              {/* Hamburger Menu end - Mobile */}
            </Stack>
          </Stack>
        </StyledToolbar>
      </AppBar>

      {/*Hamburger Menu - Mobile */}
      <HamburgerMenu
        openMobileMenu={openMenu}
        handleMenuClose={handleMenuClose}
        isEmployer={isEmployer}
        isLoggedIn={isLoggedIn}
        hideLanguageSwitcher={hideLanguageSwitcher}
        variant={variant}
        showProfileMenuLinks={(userType && !isNoType && isLoggedIn) ?? false}
        isProfileNotCompleted={isProfileNotCompleted}
      />

      {/* Profile Menu - Desktop */}
      <ProfileMenu
        anchorEl={menuElement.current}
        openProfileMenu={openProfileMenu}
        handleClose={() => setOpenProfileMenu(false)}
      />
      <Popover
        open={isNotificationOpen}
        anchorEl={anchorNotificationEl}
        onClose={handleNotificationPopUpClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        <Box width={460}>
          {isEmployer ? (
            <EmployerNotifications renderAsPage={false} />
          ) : (
            <CandidateNotifications renderAsPage={false} />
          )}
        </Box>
      </Popover>
    </>
  );
};

export default Header;

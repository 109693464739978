import { useEffect } from "react";

import { Grid } from "@mui/material";
import useForceUpdate from "use-force-update";

import CandidateAccountInformation from "@components/CandidateAccountInformation";
import CandidateAccountManagement from "@components/CandidateAccountManagement";
import Paper from "@components/Paper";
import SideMenu from "@components/SideMenu";
import Typography from "@components/Typography";

import translate, { intl } from "@utils/translate";

const UserAccountSettings = () => {
  const menuLinks = [
    {
      label: intl.get("t_account_setting_side_menu_account_setting"),
      redirectLink: "/profile/settings"
    },
    {
      label: intl.get("t_account_setting_side_menu_user_terms"),
      redirectLink: "/user-terms-and-conditions",
      openInNewTab: true
    },
    {
      label: intl.get("t_account_setting_side_menu_privacy_policy"),
      redirectLink: "/privacy-policy",
      openInNewTab: true
    }
  ];

  const forceUpdate = useForceUpdate();
  const languageChanged = () => {
    forceUpdate();
  };

  useEffect(() => {
    translate.addLanguageChangedListener(languageChanged);
    return () => {
      translate.removeLanguageChangedListener(languageChanged);
    };
  }, []);

  return (
    <>
      <Typography variant="h3" color="white">
        {intl.get("t_account_setting_page_title")}
      </Typography>

      <Grid container mt={4} rowGap={3}>
        <Grid item xs={12} md={3} pr={{ xs: 0, md: 2 }}>
          {/* SideMenu */}
          <SideMenu menuLinks={menuLinks} />
        </Grid>
        <Grid item xs={12} md={9} pl={{ xs: 0, md: 2 }}>
          {/* Main Content */}
          <Paper>
            <Typography variant="h3">
              {intl.get("t_account_setting_title")}
            </Typography>

            <CandidateAccountInformation />
            <CandidateAccountManagement />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default UserAccountSettings;

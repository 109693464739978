import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  AccountBalanceWalletRounded as AccountBalanceWalletRoundedIcon,
  InfoRounded as InfoRoundedIcon
} from "@mui/icons-material";
import {
  Alert,
  Box,
  DialogActions,
  DialogContent,
  Stack,
  styled,
  Tooltip
} from "@mui/material";
import {
  arrayUnion,
  doc,
  getDoc,
  Timestamp,
  updateDoc
} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import * as yup from "yup";

import SkeletonButton from "@skeletons/SkeletonButton";

import Button from "@components/Button";
import SelectionProcess from "@components/CandidateResumeDrawer/SelectionProcess";
import ProfileSummaryTab from "@components/CandidateResumeDrawer/Tabs/ProfileSummaryTab";
import ResumeTab from "@components/CandidateResumeDrawer/Tabs/ResumeTab";
import Checkbox from "@components/Checkbox";
import CVFilledStatus from "@components/CVFilledStatus";
import { Row } from "@components/DataTable/ApplicantsDataTable";
import Dialog from "@components/Dialog";
import DownloadFilesAsZipButton from "@components/DownloadFilesAsZipButton";
import Typography from "@components/Typography";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import useUserProfile from "@hooks/database/useUserProfile";
import { useOptions } from "@hooks/useOptions";
import useToast from "@hooks/useToast";

import ApplicationID from "@interfaces/database/ApplicationID";
import JobApplicationInvitationAction from "@interfaces/database/JobApplicationInvitationAction";
import JobID from "@interfaces/database/JobID";
import JobProfile from "@interfaces/database/JobProfile";
import MultiLingual from "@interfaces/database/MultiLingual";
import UserID from "@interfaces/database/UserID";
import UserProfile from "@interfaces/database/UserProfile";

import EnvironmentSpecific from "@utils/components/EnvironmentSpecific";
import {
  ANIMATION_TIMEOUT_MS,
  DIALOG_ACTION,
  EMPLOYER_DECLINED_CANDIDATE_REASONS,
  EMPLOYER_DECLINED_CANDIDATE_REASONS_T_LABELS,
  ENVIRONMENT,
  FILES_LOCATION_INDIVIDUAL_AUTOMATED_EN_CV,
  FILES_LOCATION_INDIVIDUAL_AUTOMATED_JA_CV,
  FILES_LOCATION_INDIVIDUAL_AUTOMATED_JA_RIREKISHO,
  FILES_LOCATION_INDIVIDUAL_EN_CV,
  FILES_LOCATION_INDIVIDUAL_JA_CV,
  FILES_LOCATION_INDIVIDUAL_JA_RIREKISHO,
  FIRESTORE_COLLECTIONS,
  FREE_TEXT_FIELD_MAX_LENGTH,
  JOB_APPLICATION_INVITATION_ACTION_TYPE,
  LOCALE,
  RESUME_PURCHASE_STATUS_CODE,
  USER_STATUS
} from "@utils/config";
import shouldExecuteInEnvironment from "@utils/envSpecific";
import { auth, db } from "@utils/firebase";
import generateHiringProcessSteps from "@utils/generateHiringProcessSteps";
import { resolveMultiLingual } from "@utils/multiLingual";
import {
  canPurchaseResume,
  isCandidateProfilePurchased,
  purchaseResume
} from "@utils/purchaseResume";
import translate, { intl, intlEn, intlJa } from "@utils/translate";

import Sentiment_Sad from "@assets/images/Sentiment_Sad.png";

interface AppliedResumeDrawerProps {
  tableRowData: Row;
  handleTableRowData: (
    applicationId: ApplicationID,
    updatedTableRowData: Row
  ) => void;
  candidateId: UserID;
  candidateProfilePicUrl: string;
  applicationId: ApplicationID;
  hiringFlowActiveStepKey: string;
  jobId: JobID;
  jobData?: JobProfile;
  applicationStatus: typeof JOB_APPLICATION_INVITATION_ACTION_TYPE[keyof typeof JOB_APPLICATION_INVITATION_ACTION_TYPE];
  handleError: () => void;
  handleDecline?: (declinedReasons?: Array<MultiLingual<string>>) => void;
  hideDecline?: boolean;
}

interface DeclinedReasonsFormData {
  declinedReasons: Array<
    keyof typeof EMPLOYER_DECLINED_CANDIDATE_REASONS_T_LABELS
  >;
  otherDeclinedReason: string;
}

const StyledAlert = styled(Alert)({
  boxShadow: "none !important",
  marginBottom: 16
});

const AppliedResumeDrawer = ({
  tableRowData,
  handleTableRowData,
  candidateId,
  candidateProfilePicUrl,
  applicationId,
  hiringFlowActiveStepKey,
  jobId,
  jobData,
  applicationStatus,
  handleError,
  handleDecline
}: AppliedResumeDrawerProps) => {
  const [user] = useAuthState(auth);
  const userId = user?.uid ?? "";
  const companyUserProfile = useUserProfile();
  const companyDetails = useCompanyDetails();
  const toast = useToast();
  const navigate = useNavigate();

  const managerId = user?.uid ?? "";
  const companyId = companyUserProfile.value?.company_id ?? "";
  const creditsAvailable = companyDetails.value?.total_credits_available ?? 0;

  const [candidate, setCandidate] = useState<UserProfile | null>(null);
  const [isResumePurchased, setIsResumePurchased] = useState<boolean>(false);
  const [canPurchaseResumeStatus, setCanPurchaseResumeStatus] = useState<
    | typeof RESUME_PURCHASE_STATUS_CODE[keyof typeof RESUME_PURCHASE_STATUS_CODE]
    | null
  >(null);
  const [
    checkingResumePurchaseEligibility,
    setCheckingResumePurchaseEligibility
  ] = useState<boolean>(false);
  const [isPurchaseLoading, setIsPurchaseLoading] = useState<boolean>(false);

  const enCVFileInfo = resolveMultiLingual(
    candidate?.cv?.file_information,
    LOCALE.EN,
    true
  );
  const jaCVFileInfo = resolveMultiLingual(
    candidate?.cv?.file_information,
    LOCALE.JA,
    true
  );
  const jaRirekishoFileInfo = candidate?.ja_rirekisho?.file_information;

  const getCandidateFullName = () => {
    let candidateFullName = "";
    if (candidate?.summary?.basic_information?.first_name) {
      candidateFullName += resolveMultiLingual(
        candidate?.summary?.basic_information?.first_name
      );
    }
    if (candidate?.summary?.basic_information?.last_name) {
      candidateFullName += ` ${resolveMultiLingual(
        candidate?.summary?.basic_information?.last_name
      )}`;
    }
    candidateFullName = candidateFullName.trim();
    if (!candidateFullName) {
      candidateFullName = intl.get("t_resumes");
    }
    return candidateFullName;
  };

  const candidateFullName = getCandidateFullName();

  const fileInformation = [
    {
      name: `CV${candidateFullName ? " " + candidateFullName : ""}`,
      path: `${FILES_LOCATION_INDIVIDUAL_AUTOMATED_EN_CV}/${candidateId}.pdf`,
      extension: "pdf"
    },
    {
      name: `職務経歴書${candidateFullName ? " " + candidateFullName : ""}`,
      path: `${FILES_LOCATION_INDIVIDUAL_AUTOMATED_JA_CV}/${candidateId}.pdf`,
      extension: "pdf"
    },
    {
      name: `履歴書${candidateFullName ? " " + candidateFullName : ""}`,
      path: `${FILES_LOCATION_INDIVIDUAL_AUTOMATED_JA_RIREKISHO}/${candidateId}.pdf`,
      extension: "pdf"
    }
  ];

  if (enCVFileInfo?.name && enCVFileInfo?.extension) {
    fileInformation.push({
      name: enCVFileInfo.name,
      path: `${FILES_LOCATION_INDIVIDUAL_EN_CV}/${candidateId}.${enCVFileInfo.extension}`,
      extension: enCVFileInfo.extension
    });
  }

  if (jaCVFileInfo?.name && jaCVFileInfo?.extension) {
    fileInformation.push({
      name: jaCVFileInfo.name,
      path: `${FILES_LOCATION_INDIVIDUAL_JA_CV}/${candidateId}.${jaCVFileInfo.extension}`,
      extension: jaCVFileInfo.extension
    });
  }

  if (jaRirekishoFileInfo?.name && jaRirekishoFileInfo?.extension) {
    fileInformation.push({
      name: jaRirekishoFileInfo.name,
      path: `${FILES_LOCATION_INDIVIDUAL_JA_RIREKISHO}/${candidateId}.${jaRirekishoFileInfo.extension}`,
      extension: jaRirekishoFileInfo.extension
    });
  }

  const EMPLOYER_DECLINED_CANDIDATE_REASONS_OPTIONS = useOptions(
    EMPLOYER_DECLINED_CANDIDATE_REASONS,
    EMPLOYER_DECLINED_CANDIDATE_REASONS_T_LABELS
  ).map((singleOption) => {
    return { value: singleOption.key, label: singleOption.label };
  });

  // validation schema
  const schema = yup.object({
    declinedReasons: yup.array(),
    otherDeclinedReason: yup
      .string()
      .trim()
      .max(
        FREE_TEXT_FIELD_MAX_LENGTH,
        intl.get("t_error_max_limit", {
          field: intl.get("t_general_first_name"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        })
      )
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      declinedReasons: [],
      otherDeclinedReason: ""
    }
  });

  const { handleSubmit, control, setValue } = methods;

  useEffect(() => {
    handleResumePurchaseChecks();
    fetchUserDetails(candidateId);
  }, []);

  const handleFormSubmit = (formData: DeclinedReasonsFormData) => {
    const allJobDeclinedReasons = [
      ...formData.declinedReasons
      // formData.otherDeclinedReason // FIXME: add this in phase-2
    ]?.map((singleJobDeclinedReason) => {
      return {
        en: intlEn.get(
          EMPLOYER_DECLINED_CANDIDATE_REASONS_T_LABELS[
            singleJobDeclinedReason as keyof typeof EMPLOYER_DECLINED_CANDIDATE_REASONS_T_LABELS
          ]
        ),
        ja: intlJa.get(
          EMPLOYER_DECLINED_CANDIDATE_REASONS_T_LABELS[
            singleJobDeclinedReason as keyof typeof EMPLOYER_DECLINED_CANDIDATE_REASONS_T_LABELS
          ]
        )
      };
    });
    handleDecline?.(allJobDeclinedReasons);
  };

  const fetchUserDetails = async (candidateId: string) => {
    try {
      const userRef = doc(db, FIRESTORE_COLLECTIONS.USERS, candidateId);
      const userSnapshot = await getDoc(userRef);
      if (userSnapshot.data()) {
        setCandidate(userSnapshot.data() as UserProfile);
      } else {
        handleError();
      }
    } catch (error) {
      handleError();
    }
  };

  const handleResumePurchaseModalClose = async (
    reason: keyof typeof DIALOG_ACTION
  ) => {
    if (
      reason === DIALOG_ACTION.AGREE &&
      companyUserProfile?.value?.user_type // TODO: Remove condition when env specific is removed
    ) {
      if (
        shouldExecuteInEnvironment({
          env: ENVIRONMENT.STAGE,
          userType: companyUserProfile?.value?.user_type
        })
      ) {
        if (
          canPurchaseResumeStatus === RESUME_PURCHASE_STATUS_CODE.NO_CREDITS
        ) {
          setTimeout(() => {
            navigate(
              `/${translate.getCurrentLocale()}/employers/settings/plan`
            );
          }, ANIMATION_TIMEOUT_MS.SLOW);
          return;
        }
      }
      handlePurchaseResume();
    }
  };

  const handleDeclineResumeModalClose = async (
    reason: keyof typeof DIALOG_ACTION
  ) => {
    if (reason === DIALOG_ACTION.AGREE && handleDecline) {
      handleDecline();
    }
  };

  const handleResumePurchaseChecks = async () => {
    setCheckingResumePurchaseEligibility(true);
    // #1 - Check if resume is already purchased
    const purchaseStatus = await isCandidateProfilePurchased(
      candidateId,
      companyUserProfile,
      managerId
    );
    setIsResumePurchased(purchaseStatus);

    // #2 - Check if company is eligible to purchase the resume
    if (!purchaseStatus) {
      const canPurchaseCheck = await canPurchaseResume(
        jobId,
        applicationId,
        companyUserProfile,
        managerId,
        creditsAvailable
      );
      setCanPurchaseResumeStatus(canPurchaseCheck);
    }
    setCheckingResumePurchaseEligibility(false);
  };

  const handlePurchaseSuccess = () => {
    toast.kampai(intl.get("t_toast_purchase_success"), "success");
  };

  const handlePurchaseFail = () => {
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handlePurchaseResume = async () => {
    try {
      setIsPurchaseLoading(true);

      const purchaseSuccess = await purchaseResume(
        jobId,
        applicationId,
        companyUserProfile,
        managerId,
        creditsAvailable
      );
      if (purchaseSuccess) {
        handlePurchaseSuccess();
        setIsResumePurchased(true);
      } else {
        handlePurchaseFail();
      }
      setIsPurchaseLoading(false);
    } catch (error) {
      handlePurchaseFail();
    }
  };

  const applicationRef = doc(
    db,
    FIRESTORE_COLLECTIONS.JOBS,
    jobId,
    FIRESTORE_COLLECTIONS.APPLICATIONS,
    applicationId
  );

  const handleSelectionProcess = async (currentActiveStepKey: string) => {
    if (applicationStatus === JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED) {
      return;
    }
    const newHiringFlowStepKey =
      currentActiveStepKey === JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED
        ? ""
        : currentActiveStepKey;
    const newApplicationStatus =
      currentActiveStepKey === JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED
        ? JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED
        : JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRING_FLOW_STEP_CHANGED;
    const applicationAction: JobApplicationInvitationAction = {
      action_type: newApplicationStatus,
      initiator_user_id: userId,
      updated_at: Timestamp.now(),
      metadata: {
        hiring_flow_active_step_key: newHiringFlowStepKey
      }
    };
    try {
      handleTableRowData(applicationId, {
        ...tableRowData,
        hiringFlowActiveStepKey: newHiringFlowStepKey,
        stage: newApplicationStatus
      });
      await updateDoc(applicationRef, {
        actions: arrayUnion(applicationAction),
        hiring_flow_active_step_key:
          currentActiveStepKey === JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED
            ? ""
            : currentActiveStepKey
      });
    } catch (err) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  };

  const DeclineButton = () => {
    if (applicationStatus === JOB_APPLICATION_INVITATION_ACTION_TYPE.DECLINED) {
      return (
        <Button disabled variant={isResumePurchased ? "contained" : "outlined"}>
          {intl.get("t_employer_dashboard_application_already_declined")}
        </Button>
      );
    } else if (
      applicationStatus === JOB_APPLICATION_INVITATION_ACTION_TYPE.INVITED ||
      applicationStatus === JOB_APPLICATION_INVITATION_ACTION_TYPE.REVOKED ||
      applicationStatus ===
        JOB_APPLICATION_INVITATION_ACTION_TYPE.INVITED_DECLINED ||
      applicationStatus === JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED
    ) {
      return (
        <Button disabled variant={isResumePurchased ? "contained" : "outlined"}>
          {intl.get("t_employer_dashboard_application_cannot_decline")}
        </Button>
      );
    } else {
      return (
        <Dialog
          isStopEventPropagation
          title={intl.get("t_dialog_decline_candidate_confirmation_title")}
          maxWidth="sm"
          initiator={
            <Button variant={isResumePurchased ? "contained" : "outlined"}>
              {intl.get("t_general_employer_decline")}
            </Button>
          }
          onClose={handleDeclineResumeModalClose}>
          {(handleAgree, handleCancel) => (
            <>
              <Box
                noValidate
                component="form"
                onSubmit={handleSubmit(handleFormSubmit)}>
                <DialogContent sx={{ py: 1 }}>
                  <Typography color="text.secondary" variant="body1">
                    {intl.get("t_employer_declined_candidate_dialog_subtitle")}
                  </Typography>
                  <Checkbox
                    size="medium"
                    name="declinedReasons"
                    control={control}
                    setValue={setValue}
                    options={EMPLOYER_DECLINED_CANDIDATE_REASONS_OPTIONS}
                  />
                  {/* FIXME: this is phase2 - need to handle email and phone number check */}
                  {/* <TextField
                    control={control}
                    name="otherDeclinedReason"
                    placeholder={intl.get("t_general_other_decline_reason")}
                  /> */}
                </DialogContent>
                <DialogActions>
                  <Button handleClick={handleCancel} variant="outlined">
                    {intl.get("t_dialog_decline_candidate_cancel_button")}
                  </Button>
                  <Button type="submit">
                    {intl.get("t_dialog_decline_candidate_agree_button")}
                  </Button>
                </DialogActions>
              </Box>
            </>
          )}
        </Dialog>
      );
    }
  };

  return (
    <>
      <Stack
        position="absolute"
        top={18}
        left={60}
        flexDirection="row"
        justifyContent={{
          xs: "flex-end",
          md: isResumePurchased ? "flex-end" : "space-between"
        }}
        alignItems="center"
        pl={{ xs: 2.5, md: 3, lg: 4 }}
        width="calc(100% - 110px)">
        {isResumePurchased ? (
          <Stack direction="row" columnGap={1}>
            <DownloadFilesAsZipButton
              filesInformation={fileInformation}
              zipFileName={`${getCandidateFullName()}`}
              btnLabel={intl.get("t_resumes_download_button_label")}
            />
            <DeclineButton />
          </Stack>
        ) : (
          <>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              maxWidth={{ md: "60%" }}
              display={{ xs: "none", md: "flex" }}>
              <InfoRoundedIcon color="info" />
              <Typography variant="subtitle4" ml={1}>
                {intl.get("t_employer_purchase_resume_info")}
              </Typography>
            </Stack>
            <Stack direction="row" gap={3}>
              {checkingResumePurchaseEligibility ? (
                <SkeletonButton />
              ) : canPurchaseResumeStatus ===
                RESUME_PURCHASE_STATUS_CODE.CAN_PURCHASE ? (
                <Dialog
                  title={intl.get("t_resume_purchase_modal_title")}
                  maxWidth="sm"
                  initiator={
                    <Button loading={isPurchaseLoading}>
                      {intl.get("t_general_buy_resume")}
                    </Button>
                  }
                  onClose={handleResumePurchaseModalClose}>
                  {(handleAgree, handleCancel) => (
                    <>
                      <DialogContent sx={{ py: 1 }}>
                        <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
                          <Typography color="text.secondary">
                            {intl.get("t_resume_purchase_modal_subtitle")}
                          </Typography>
                          <Typography color="text.secondary">
                            {intl.get(
                              "t_resume_purchase_modal_description_one"
                            )}
                          </Typography>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            mt={1}
                            mb={3}>
                            <AccountBalanceWalletRoundedIcon />
                            <Typography>
                              {intl.get("t_resume_purchase_modal_credit_left", {
                                credits: creditsAvailable
                              })}
                            </Typography>
                          </Stack>
                        </EnvironmentSpecific>
                        <Typography color="text.secondary" mb={3}>
                          {intl.getHTML(
                            "t_resume_purchase_modal_description_two"
                          )}
                        </Typography>
                        <Stack gap={2}>
                          <CVFilledStatus
                            label={intl.get("t_general_english_cv")}
                            isAttached={
                              candidate?.metadata?.progress_flags?.en_cv ??
                              false
                            }
                          />
                          <CVFilledStatus
                            label={intl.get("t_general_japanese_cv")}
                            isAttached={
                              candidate?.metadata?.progress_flags?.ja_cv ??
                              false
                            }
                          />
                          <CVFilledStatus
                            label={intl.get("t_general_japanese_rirekisho")}
                            isAttached={
                              candidate?.metadata?.progress_flags
                                ?.ja_rirekisho ?? false
                            }
                          />
                        </Stack>
                      </DialogContent>
                      <DialogActions>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          width="100%"
                          padding={1}>
                          <Button handleClick={handleCancel} variant="outlined">
                            {intl.get("t_general_cancel")}
                          </Button>
                          <Button handleClick={handleAgree}>
                            {intl.get("t_general_confirm")}
                          </Button>
                        </Stack>
                      </DialogActions>
                    </>
                  )}
                </Dialog>
              ) : canPurchaseResumeStatus ===
                RESUME_PURCHASE_STATUS_CODE.NO_CREDITS ? (
                <Dialog
                  maxWidth="sm"
                  initiator={
                    <Button>{intl.get("t_general_buy_resume")}</Button>
                  }
                  onClose={handleResumePurchaseModalClose}>
                  {(handleAgree, handleCancel) => (
                    <>
                      <DialogContent>
                        <Stack
                          alignItems="center"
                          justifyContent="center"
                          gap={2}
                          pb={1}>
                          <img
                            src={Sentiment_Sad}
                            alt={intl.get("t_alt_sentiment_sad")}
                            width={40}
                          />
                          <Typography variant="h5" textAlign="center">
                            {intl.get(
                              "t_resume_purchase_no_credit_modal_title"
                            )}
                          </Typography>
                          <Typography color="text.secondary">
                            {intl.get(
                              "t_resume_purchase_no_credit_modal_subtitle"
                            )}
                          </Typography>
                        </Stack>
                      </DialogContent>
                      <DialogActions>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          width="100%"
                          padding={1}>
                          <Button handleClick={handleCancel} variant="outlined">
                            {intl.get("t_general_cancel")}
                          </Button>
                          <Button handleClick={handleAgree}>
                            {intl.get(
                              "t_resume_purchase_no_credit_purchase_btn"
                            )}
                          </Button>
                        </Stack>
                      </DialogActions>
                    </>
                  )}
                </Dialog>
              ) : (
                <Tooltip
                  arrow
                  placement="bottom"
                  enterTouchDelay={0}
                  title={
                    <Typography variant="body2">
                      {intl.get(
                        "t_employer_dashboard_disable_purchase_message"
                      )}
                    </Typography>
                  }>
                  <span>
                    <Button disabled>{intl.get("t_general_buy_resume")}</Button>
                  </span>
                </Tooltip>
              )}
              <DeclineButton />
            </Stack>
          </>
        )}
      </Stack>
      {/* Mobile only */}
      {!isResumePurchased ? (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          maxWidth={{ md: "60%" }}
          display={{ xs: "flex", md: "none" }}
          mt={4}
          px={2.5}>
          <InfoRoundedIcon color="info" />
          <Typography variant="subtitle4" ml={1}>
            {intl.get("t_employer_purchase_resume_info")}
          </Typography>
        </Stack>
      ) : (
        false
      )}

      {/* selection process start*/}
      {isResumePurchased &&
      jobData?.hiring_flow &&
      applicationStatus !== JOB_APPLICATION_INVITATION_ACTION_TYPE.DECLINED ? (
        <Box px={{ xs: 2.5, md: 3, lg: 4 }} mt={4}>
          <SelectionProcess
            handleSelectionProcess={handleSelectionProcess}
            hiringFlowProcessSteps={generateHiringProcessSteps(
              jobData?.hiring_flow
            )}
            currentActiveStepKey={
              applicationStatus === JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED
                ? ""
                : hiringFlowActiveStepKey
            }
            isHired={
              applicationStatus === JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED
            }
          />
        </Box>
      ) : (
        false
      )}
      {/* selection process end*/}

      <Box px={{ xs: 2.5, md: 3, lg: 4 }} mt={4}>
        {candidate?.status === USER_STATUS.LOCKED ? (
          <StyledAlert severity="error" variant="filled">
            <Typography variant="body1">
              {intl.get("t_candidate_unavailable_banner")}
            </Typography>
          </StyledAlert>
        ) : (
          false
        )}

        {isResumePurchased ? (
          <ResumeTab
            userId={candidateId}
            user={candidate}
            companyId={companyId}
          />
        ) : (
          <ProfileSummaryTab
            user={candidate}
            userProfilePicUrl={candidateProfilePicUrl}
          />
        )}
      </Box>
    </>
  );
};

export default AppliedResumeDrawer;

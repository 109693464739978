import { useEffect, useRef, useState } from "react";

import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { NavigateBefore as NavigateBeforeIcon } from "@mui/icons-material";
import {
  Box,
  Grid,
  Stack,
  styled,
  useMediaQuery,
  useTheme
} from "@mui/material";
import dayjs from "dayjs";
import { logEvent } from "firebase/analytics";
import { doc, getDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useAuthState } from "react-firebase-hooks/auth";
import { SearchParams } from "typesense/lib/Typesense/Documents";
import * as yup from "yup";

import SkeletonJobCard from "@skeletons/SkeletonJobCard";
import SkeletonJobDetailCard from "@skeletons/SkeletonJobDetailCard";
import SkeletonTypography from "@skeletons/SkeletonTypography";

import Button from "@components/Button";
import JobCard from "@components/JobCards/JobCard";
import JobDetailCard from "@components/JobDetailCard";
import NonTypeableSelect from "@components/NonTypeableSelect";
import Pagination from "@components/Pagination";
import SearchHeader from "@components/SearchHeader";
import Typography from "@components/Typography";

import useUserProfile from "@hooks/database/useUserProfile";
import { useOptions } from "@hooks/useOptions";
import useToast from "@hooks/useToast";

import CompanyID from "@interfaces/database/CompanyID";
import CompanyProfile from "@interfaces/database/CompanyProfile";
import JobID from "@interfaces/database/JobID";
import JobProfile from "@interfaces/database/JobProfile";
import MultiLingual from "@interfaces/database/MultiLingual";
import JobProfileWrapped from "@interfaces/functions/JobProfileWrapped";

import { canUserApply, handleJobApply } from "@utils/applyJob";
import { canUserBookmark, handleJobBookmarkChange } from "@utils/bookmarkJob";
import {
  ANALYTICS_EVENT_TYPE,
  FILES_LOCATION_COMPANY_LOGO,
  FIRESTORE_COLLECTIONS,
  JOB_ACTIVE_TAG_COMPANY_LAST_LOGIN_DAY,
  JOB_CONTRACT_TYPE,
  JOB_NEW_TAG_LAST_UPDATED_DAY,
  JOB_REMOTE_WORK_TYPE,
  JOB_REMOTE_WORK_TYPE_T_LABELS,
  JOB_SALARY_DEPOSIT_FREQUENCY,
  JOB_SEARCH_SORT_ORDER,
  JOB_SEARCH_SORT_ORDER_T_LABELS,
  JOB_STATUS_TAGS,
  JOB_VISA_SPONSORSHIP_AVAILABLE,
  LANGUAGE_PROFICIENCY,
  MIN_YEARS_OF_EXPERIENCE_T_LABELS,
  PAGINATION,
  TYPESENSE_COLLECTIONS
} from "@utils/config";
import {
  rawDraftContentStateToHTML,
  rawDraftContentStateToString
} from "@utils/convertDraftContentState";
import { getDaysAgo } from "@utils/dateTime";
import { analytics, auth, db, functions } from "@utils/firebase";
import { getFileURL } from "@utils/getFileURL";
import { getCountryCode, isValidCountryCode } from "@utils/location";
import { resolveMultiLingual } from "@utils/multiLingual";
import { roundSalary } from "@utils/roundSalary";
import translate, {
  intl,
  LOCALE_DATA,
  LOCALE_DATA_LOWERCASE
} from "@utils/translate";
import getClient from "@utils/typesense";

interface JobListSingleJob {
  company_id: string;
  company_logo_extension: string;
  company_name_en: string;
  company_name_ja: string;
  contract_type: string;
  id: string;
  job_description_en: string;
  job_description_ja: string;
  job_id: string;
  language_requirements_en: number;
  language_requirements_ja: number;
  location_city: string;
  location_country: string;
  minimum_experience: number;
  remote_work: string;
  salary_max: number;
  salary_min: number;
  skills: Array<string>;
  title_en: string;
  title_ja: string;
  unix_last_login_at: number;
  unix_updated_at: number;
  unix_company_logo_updated_at: number;
  visa_sponsorship: string;
}
interface JobListMeta {
  total_hits: number;
  search_time_ms: number;
}

interface SearchProps {
  searchSameCompany?: boolean;
}

const Search = ({ searchSameCompany = false }: SearchProps) => {
  const { job_id: paramJobId } = useParams();
  const jobId = paramJobId ?? "";
  const [searchParams, setSearchParams] = useSearchParams();

  const toast = useToast();

  const [isJobsLoading, setIsJobsLoading] = useState<boolean>(true);
  const [isJobDataLoading, setIsJobDataLoading] = useState<boolean>(true);

  const [jobs, setJobs] = useState<Array<JobListSingleJob>>([]);
  const [jobsCount, setJobsCount] = useState<number>(0);
  const [jobData, setJobData] = useState<JobProfileWrapped>();

  const userProfile = useUserProfile();
  const [user] = useAuthState(auth);
  const userId = user?.uid ?? "";
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down("md"));

  const searchListRef = useRef<HTMLDivElement>(null);

  const JOB_SEARCH_SORT_ORDER_OPTIONS = useOptions(
    JOB_SEARCH_SORT_ORDER,
    JOB_SEARCH_SORT_ORDER_T_LABELS
  );

  const getJobList = async (): Promise<
    [Array<JobListSingleJob>, JobListMeta]
  > => {
    const typesenseClient = getClient();
    const typesenseSearchParams: SearchParams = {
      q: "",
      query_by: ["title_ja", "title_en", "company_name_ja", "company_name_en"],
      filter_by: "",
      infix: ["off", "off", "off", "off"]
    };

    const filters = [];

    const keyword = searchParams.get("q");
    if (keyword) {
      typesenseSearchParams.q = keyword;
    }

    const location = searchParams.get("location");
    if (location) {
      if (isValidCountryCode(location)) {
        filters.push(`location_country:=${location}`);
      } else if (getCountryCode(location)) {
        filters.push(`location_country:=${getCountryCode(location)}`);
      } else {
        filters.push(`location_city:=${location}`);
      }
    }

    const minSalary = searchParams.get("min_salary");
    if (minSalary && !isNaN(parseInt(minSalary)) && parseInt(minSalary) >= 0) {
      filters.push(
        `salary_min:<=${parseInt(searchParams.get("min_salary") ?? "")}`
      );
      filters.push(
        `salary_max:>=${parseInt(searchParams.get("min_salary") ?? "")}`
      );
    }

    const remoteAllowed = searchParams.getAll("remote_allowed");
    if (remoteAllowed.length > 0) {
      const remoteAllowedValues = [];
      for (const singleRemoteAllowed of remoteAllowed) {
        if (
          (Object.values(JOB_REMOTE_WORK_TYPE) as Array<string>).includes(
            singleRemoteAllowed
          )
        ) {
          remoteAllowedValues.push(singleRemoteAllowed);
        }
      }
      if (remoteAllowedValues.length > 0) {
        filters.push(`remote_work:=[${remoteAllowedValues.join(",")}]`);
      }
    }

    const contractType = searchParams.getAll("contract_type");
    if (contractType.length > 0) {
      const contractTypeValues = [];
      for (const singleContractType of contractType) {
        if (
          (Object.values(JOB_CONTRACT_TYPE) as Array<string>).includes(
            singleContractType
          )
        ) {
          contractTypeValues.push(singleContractType);
        }
      }
      if (contractTypeValues.length > 0) {
        filters.push(`contract_type:=[${contractTypeValues.join(",")}]`);
      }
    }

    const visaSponsorship = searchParams.get("visa_sponsorship");
    if (
      visaSponsorship &&
      (Object.values(JOB_VISA_SPONSORSHIP_AVAILABLE) as Array<string>).includes(
        visaSponsorship
      )
    ) {
      filters.push(`visa_sponsorship:=${visaSponsorship}`);
    }

    const minExperience = searchParams.get("min_experience");
    if (
      minExperience &&
      !isNaN(parseInt(minExperience)) &&
      parseInt(minExperience) >= 0
    ) {
      filters.push(
        `minimum_experience:<=${parseInt(
          searchParams.get("min_experience") ?? ""
        )}`
      );
    }

    const jaLevel = searchParams.getAll("language_ja_level");
    if (jaLevel.length > 0) {
      const jaLevelValues = [];
      for (const singleJaLevel of jaLevel) {
        if (
          (Object.values(LANGUAGE_PROFICIENCY) as Array<string>).includes(
            singleJaLevel
          )
        ) {
          jaLevelValues.push(singleJaLevel);
        }
      }
      if (jaLevelValues.length > 0) {
        filters.push(`language_requirements_ja:=[${jaLevelValues.join(",")}]`);
      }
    }

    const enLevel = searchParams.getAll("language_en_level");
    if (enLevel.length > 0) {
      const enLevelValues = [];
      for (const singleEnLevel of enLevel) {
        if (
          (Object.values(LANGUAGE_PROFICIENCY) as Array<string>).includes(
            singleEnLevel
          )
        ) {
          enLevelValues.push(singleEnLevel);
        }
      }
      if (enLevelValues.length > 0) {
        filters.push(`language_requirements_en:=[${enLevelValues.join(",")}]`);
      }
    }

    const skills = searchParams.getAll("skills");
    if (skills.length > 0) {
      for (const singleSkill of skills) {
        filters.push(`skills:=${singleSkill}`);
      }
    }

    /**
     * This is the posted company ID.
     * This posted company can post jobs for both self and client.
     * In that case, it will show both.
     * (As long as they are posted by the same company).
     */
    const companyId = searchParams.get("company_id");
    if (companyId) {
      filters.push(`company_id:=${companyId}`);
    }

    if (filters.length > 0) {
      typesenseSearchParams.filter_by = filters.join(" && ");
    }

    if (jobId !== "") {
      typesenseSearchParams.pinned_hits = `${jobId}:1`;
    }
    typesenseSearchParams.per_page = PAGINATION.ITEMS_PER_PAGE;

    const pageNo = searchParams.get("page");
    if (pageNo && !isNaN(parseInt(pageNo)) && parseInt(pageNo) >= 0) {
      typesenseSearchParams.page = parseInt(pageNo);
    }

    const sortBy = searchParams.get("sort");
    if (sortBy && sortBy === "newest") {
      typesenseSearchParams.sort_by = "unix_updated_at:desc";
    } else {
      typesenseSearchParams.sort_by = "unix_last_login_at:desc";
    }

    try {
      const jobList = await typesenseClient
        .collections(TYPESENSE_COLLECTIONS.JOBS)
        .documents()
        .search(typesenseSearchParams);
      if (jobList.hits && Array.isArray(jobList.hits)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const jobs = jobList.hits.map((job: any) => job.document);
        // FIXME: A Typesense Interface needs to be prepared.
        const metadata = {
          total_hits: jobList.found,
          search_time_ms: jobList.search_time_ms
        };
        return [jobs, metadata];
      }
    } catch (e) {
      return [
        [],
        {
          total_hits: 0,
          search_time_ms: 0
        }
      ];
    }
    return [
      [],
      {
        total_hits: 0,
        search_time_ms: 0
      }
    ];
  };

  const navigateJobIdURL = (newJobId: string, replace = false) => {
    if (jobId === newJobId) {
      return;
    }
    setJobData(undefined);
    setIsJobDataLoading(true);
    let url = `/${translate.getCurrentLocale()}/search/${newJobId}`;
    if (searchParams.toString()) {
      url += `?${searchParams.toString()}`;
    }
    navigate(url, { replace });
  };

  useEffect(() => {
    if (!searchParams.has("page")) {
      /**
       * Do not load jobs if company_id is not present and search for Same company is enabled.
       * We first wait for the job to load, then use the company ID from that Job.
       * Then load the related jobs for that Company.
       */
      if (searchSameCompany && !searchParams.has("company_id")) {
        return;
      }
      searchParams.set("page", "1");
      setSearchParams(searchParams);
      return;
    }
    setIsJobsLoading(true);
    (async () => {
      try {
        const [jobsList, metadata] = await getJobList();
        setJobsCount(metadata.total_hits);
        setJobs(jobsList);
        setIsJobsLoading(false);
        if (!isSmallScreen && !jobId) {
          if (jobsList.length > 0) {
            navigateJobIdURL(jobsList[0].id, true);
          } else {
            setIsJobDataLoading(false);
          }
        }
      } catch (e) {
        toast.kampai(intl.get("t_job_search_failed_error"), "error");
      }
    })();
  }, [searchParams, isSmallScreen]);

  const getCompanyData = async (companyId = "") => {
    if (!companyId) {
      return;
    }
    const companyRef = doc(db, FIRESTORE_COLLECTIONS.COMPANIES, companyId);
    const companySnap = await getDoc(companyRef);
    if (companySnap.exists()) {
      return companySnap.data() as CompanyProfile;
    }
  };

  useEffect(() => {
    if (!jobId) {
      return;
    }
    setIsJobDataLoading(true);
    (async () => {
      if (searchSameCompany && !searchParams.has("company_id")) {
        // Check if job Id is invalid. then fallback to Firestore.
        const typesenseClient = getClient();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const jobData: any = await typesenseClient
          .collections(TYPESENSE_COLLECTIONS.JOBS)
          .documents(jobId)
          .retrieve();
        if (jobData.company_id) {
          searchParams.set("company_id", jobData.company_id);
          setSearchParams(searchParams);
        }
      }

      const docRef = doc(db, FIRESTORE_COLLECTIONS.JOBS, jobId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const jobData = docSnap.data() as JobProfile;
        if (jobData && jobData.company_id) {
          const companiesData = await Promise.all([
            getCompanyData(jobData.company_id),
            getCompanyData(jobData.client_company_id)
          ]);

          const [jobPostingCompanyData] = companiesData;
          let [, jobCompanyData] = companiesData;

          // If Client company is not present, then use the Job Posting Company.
          // This is a job of the Job Posting Company.
          if (!jobCompanyData) {
            jobCompanyData = jobPostingCompanyData;
          }

          if (jobPostingCompanyData && jobCompanyData) {
            setJobData({
              job_id: jobId,
              job_profile: jobData,
              company_profile: jobCompanyData,
              agency_profile: jobPostingCompanyData
            });
            setIsJobDataLoading(false);
          }
        }
      } else {
        toast.kampai(intl.get("t_job_not_found_error"), "error");

        const syncFunction = httpsCallable(functions, "syncJobWithTypesense");
        syncFunction(jobId);

        setIsJobDataLoading(false);
      }
    })();

    logEvent(analytics, ANALYTICS_EVENT_TYPE.VIEW_JOB_PAGE, {
      job_id: jobId
    });
  }, [jobId]);

  const StyledJobSearchResultSelectedJobContainer = styled(Grid)(
    ({ theme }) => ({
      [theme.breakpoints.down("md")]: {
        paddingLeft: 0,
        display: jobId ? "block" : "none"
      },
      [theme.breakpoints.up("md")]: {
        paddingLeft: "1.5rem",
        display: "block",
        overflowY: "auto",
        maxHeight: "calc(100vh - 8rem)",
        paddingBottom: "0.5rem"
      }
    })
  );

  const resetListScroll = () => {
    searchListRef.current?.scrollTo({ top: 0 });
  };

  const appliedJobIds = userProfile.value?.jobs?.applied?.map((job) => job.id);

  let currentPage = Number(searchParams.get("page"));
  const totalPages = Math.ceil(jobsCount / PAGINATION.ITEMS_PER_PAGE);
  if (
    !currentPage ||
    isNaN(currentPage) ||
    currentPage < 1 ||
    currentPage > totalPages
  ) {
    currentPage = 1;
  }

  const methods = useForm({
    resolver: yupResolver(
      yup.object({
        sort: yup.string().nullable()
      })
    ),
    defaultValues: {
      sort: searchParams.has("sort") ? searchParams.get("sort") : ""
    }
  });

  const { control, setValue, watch } = methods;
  const sortInputValue = watch("sort");

  useEffect(() => {
    if (sortInputValue) {
      searchParams.set("sort", sortInputValue);
      setSearchParams(searchParams);
      navigateJobIdURL("");
      resetListScroll();
    }
  }, [sortInputValue]);

  const getJobStatusTags = (
    jobData: JobProfileWrapped
  ): Array<typeof JOB_STATUS_TAGS[keyof typeof JOB_STATUS_TAGS]> => {
    const statusTags: Array<
      typeof JOB_STATUS_TAGS[keyof typeof JOB_STATUS_TAGS]
    > = [];
    if (jobData.job_profile.updated_at) {
      if (
        getDaysAgo(jobData.job_profile.updated_at) <=
        JOB_NEW_TAG_LAST_UPDATED_DAY
      ) {
        statusTags.push(JOB_STATUS_TAGS.NEW);
      }
    }
    if (jobData.agency_profile) {
      statusTags.push(JOB_STATUS_TAGS.AGENCY);
    }
    if (jobData.company_profile.last_login_at) {
      if (
        getDaysAgo(jobData.company_profile.last_login_at) <=
        JOB_ACTIVE_TAG_COMPANY_LAST_LOGIN_DAY
      ) {
        statusTags.push(JOB_STATUS_TAGS.ACTIVE);
      }
    }
    return statusTags;
  };

  const handleApplyJob = async (jobId: JobID, companyId: CompanyID) => {
    try {
      if (user && jobId) {
        const applyStatus = await handleJobApply(
          jobId,
          companyId,
          userProfile,
          user
        );
        if (applyStatus) {
          toast.kampai(intl.get("t_toast_apply_job_success"), "success");
        } else {
          toast.kampai(intl.get("t_toast_apply_job_error"), "error");
        }
      }
    } catch (error) {
      toast.kampai(intl.get("t_toast_apply_job_error"), "error");
    }
  };

  const findSalaryFrequency = (
    frequency: typeof JOB_SALARY_DEPOSIT_FREQUENCY[keyof typeof JOB_SALARY_DEPOSIT_FREQUENCY]
  ) => {
    switch (frequency) {
      case JOB_SALARY_DEPOSIT_FREQUENCY.ANNUALLY:
      case JOB_SALARY_DEPOSIT_FREQUENCY.QUARTERLY:
        return "YEAR";
      case JOB_SALARY_DEPOSIT_FREQUENCY.MONTHLY:
      case JOB_SALARY_DEPOSIT_FREQUENCY.BI_WEEKLY:
        return "MONTH";
      case JOB_SALARY_DEPOSIT_FREQUENCY.WEEKLY:
        return "WEEK";
      case JOB_SALARY_DEPOSIT_FREQUENCY.DAILY:
        return "DAY";
      case JOB_SALARY_DEPOSIT_FREQUENCY.HOURLY:
        return "HOUR";
      default:
        return "YEAR";
    }
  };

  const findEmployerType = (
    employerType: typeof JOB_CONTRACT_TYPE[keyof typeof JOB_CONTRACT_TYPE]
  ) => {
    switch (employerType) {
      case JOB_CONTRACT_TYPE.FULL_TIME_EMPLOYEE:
        return "FULL_TIME";
      case JOB_CONTRACT_TYPE.PART_TIME_JOB:
        return "PART_TIME";
      case JOB_CONTRACT_TYPE.CONTRACT_EMPLOYEE:
        return "CONTRACTOR";
      case JOB_CONTRACT_TYPE.INTERNSHIP:
        return "INTERN";
      case JOB_CONTRACT_TYPE.DISPATCH_EMPLOYEE:
        return "DISPATCH";
      case JOB_CONTRACT_TYPE.FREELANCE_EMPLOYEE:
        return "FREELANCE";
      case JOB_CONTRACT_TYPE.OTHER:
        return "OTHER";
      default:
        return "OTHER";
    }
  };

  const convertToAnnualSalary = (
    salary: number,
    frequency: typeof JOB_SALARY_DEPOSIT_FREQUENCY[keyof typeof JOB_SALARY_DEPOSIT_FREQUENCY]
  ) => {
    switch (frequency) {
      case JOB_SALARY_DEPOSIT_FREQUENCY.HOURLY:
        return roundSalary(salary * 8 * 22 * 12);

      case JOB_SALARY_DEPOSIT_FREQUENCY.DAILY:
        return roundSalary(salary * 22 * 12);

      case JOB_SALARY_DEPOSIT_FREQUENCY.WEEKLY:
        return roundSalary(salary * 4 * 12);

      case JOB_SALARY_DEPOSIT_FREQUENCY.BI_WEEKLY:
        return roundSalary(salary * 2 * 12);

      case JOB_SALARY_DEPOSIT_FREQUENCY.MONTHLY:
        return roundSalary(salary * 12);

      case JOB_SALARY_DEPOSIT_FREQUENCY.QUARTERLY:
        return roundSalary(salary * 4);

      case JOB_SALARY_DEPOSIT_FREQUENCY.ANNUALLY:
      default:
        return roundSalary(salary);
    }
  };

  const convertToHigherSalaryFrequency = (
    salary: number,
    frequency: typeof JOB_SALARY_DEPOSIT_FREQUENCY[keyof typeof JOB_SALARY_DEPOSIT_FREQUENCY]
  ) => {
    switch (frequency) {
      case JOB_SALARY_DEPOSIT_FREQUENCY.BI_WEEKLY:
        return salary * 2;

      case JOB_SALARY_DEPOSIT_FREQUENCY.QUARTERLY:
        return salary * 4;

      default:
        return salary;
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {intl.get("t_seo_search_page_title", {
            jobTitle: resolveMultiLingual(jobData?.job_profile.job_title),
            companyName: resolveMultiLingual(jobData?.company_profile?.name)
          })}
        </title>
        <meta
          name="description"
          content={rawDraftContentStateToString(
            resolveMultiLingual(jobData?.job_profile?.job_description)
          )}
        />

        <meta
          property="og:url"
          content={`${
            process.env.REACT_APP_FRONTEND_URL
          }/${translate.getCurrentLocale()}/jobs/${jobId}`}
        />
        <meta
          property="og:title"
          content={intl.get("t_seo_search_page_title", {
            jobTitle: resolveMultiLingual(jobData?.job_profile.job_title),
            companyName: resolveMultiLingual(jobData?.company_profile?.name)
          })}
        />
        <meta
          property="og:description"
          content={rawDraftContentStateToString(
            resolveMultiLingual(jobData?.job_profile?.job_description)
          )}
        />
        {/* Image for facebook */}
        <meta
          property="og:image"
          content={`${
            process.env.REACT_APP_FRONTEND_URL
          }/assets/seo_resources/jobs_og_${translate.getCurrentLocaleShort()}/${
            jobData?.job_id
          }.png`}
        />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        {/* Time in UNIX seconds */}
        <meta
          property="og:updated_time"
          content={`${jobData?.job_profile?.updated_at?.seconds}`}
        />

        <meta
          name="twitter:title"
          content={intl.get("t_seo_search_page_title", {
            jobTitle: resolveMultiLingual(jobData?.job_profile.job_title),
            companyName: resolveMultiLingual(jobData?.company_profile?.name)
          })}
        />
        <meta
          name="twitter:description"
          content={rawDraftContentStateToString(
            resolveMultiLingual(jobData?.job_profile?.job_description)
          )}
        />
        {/* Image for Twitter */}
        <meta
          name="twitter:image"
          content={`${
            process.env.REACT_APP_FRONTEND_URL
          }/assets/seo_resources/jobs_og_${translate.getCurrentLocaleShort()}/${
            jobData?.job_id
          }.png`}
        />
        <meta name="twitter:image:alt" content={intl.get("t_seo_og_img_alt")} />
        <meta name="twitter:card" content="summary_large_image" />

        <meta
          name="twitter:label1"
          content={intl.get("t_seo_twitter_posted_by_label")}
        />
        <meta
          name="twitter:data1"
          content={resolveMultiLingual(jobData?.company_profile?.name)}
        />

        <meta
          name="twitter:label2"
          content={intl.get("t_seo_twitter_salary_label")}
        />
        <meta
          name="twitter:data2"
          content={`${
            jobData?.job_profile?.job_overview
              ? convertToAnnualSalary(
                  jobData?.job_profile?.job_overview?.salary?.max,
                  jobData?.job_profile?.job_overview?.salary?.frequency
                )
              : "0"
          } JPY`}
        />

        {/* Canonical Link */}
        {Object.keys(LOCALE_DATA).map((singleLocale) => {
          const url = new URL(window.location.href);
          const pathSegments = url.pathname
            .split("/")
            .filter((segment) => segment !== "");

          if (
            pathSegments[0] &&
            Object.keys(LOCALE_DATA_LOWERCASE).includes(
              pathSegments[0].toLowerCase()
            )
          ) {
            // Locale is present, update it
            pathSegments[0] = singleLocale;
          } else {
            // Locale is not present, insert it
            pathSegments.unshift(singleLocale);
          }
          url.pathname = "/" + pathSegments.join("/");

          let frontendUrl = process.env.REACT_APP_FRONTEND_URL ?? "";
          frontendUrl = frontendUrl.replace("https://", "");
          url.hostname = frontendUrl;
          url.port = "";

          return (
            <link
              rel="alternate"
              hrefLang={singleLocale}
              key={singleLocale}
              href={url.toString()}
            />
          );
        })}

        {/* Documentation - https://developers.google.com/search/docs/appearance/structured-data/job-posting */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "JobPosting",
            "title": `${
              resolveMultiLingual(jobData?.job_profile.job_title) ?? ""
            }`,
            "description": `${rawDraftContentStateToHTML(
              resolveMultiLingual(jobData?.job_profile?.job_description)
            )}`,
            "identifier": {
              "@type": "PropertyValue",
              "name": resolveMultiLingual(jobData?.company_profile?.name),
              "value": `${jobData?.job_profile?.company_id}_${jobData?.job_id}`
            },
            "datePosted": dayjs(
              jobData?.job_profile?.created_at.toDate()
            ).format("YYYY-MM-DD"),
            "employmentType": jobData?.job_profile?.job_overview
              ? findEmployerType(
                  jobData?.job_profile?.job_overview?.contract_type
                )
              : "OTHER",
            "hiringOrganization": {
              "@type": "Organization",
              "name": resolveMultiLingual(jobData?.company_profile?.name),
              "sameAs": jobData?.company_profile?.website_url,
              "logo": getFileURL(
                FILES_LOCATION_COMPANY_LOGO,
                jobData?.job_profile?.company_id,
                jobData?.company_profile?.logo?.extension,
                jobData?.company_profile?.logo?.uploaded_at
              )
            },
            "jobLocation": {
              "@type": "Place",
              "address": {
                "@type": "PostalAddress",
                "addressRegion":
                  jobData?.job_profile?.job_overview?.location?.city,
                "addressCountry":
                  jobData?.job_profile?.job_overview?.location?.country
              }
            },

            "baseSalary": {
              "@type": "MonetaryAmount",
              "currency": "JPY",
              "value": {
                "@type": "QuantitativeValue",
                "maxValue": jobData?.job_profile?.job_overview
                  ? convertToHigherSalaryFrequency(
                      jobData?.job_profile?.job_overview?.salary?.max,
                      jobData?.job_profile?.job_overview?.salary?.frequency
                    )
                  : 0,
                "minValue": jobData?.job_profile?.job_overview
                  ? convertToHigherSalaryFrequency(
                      jobData?.job_profile?.job_overview?.salary?.min !== 0
                        ? jobData?.job_profile?.job_overview?.salary?.min
                        : jobData?.job_profile?.job_overview?.salary?.max,
                      jobData?.job_profile?.job_overview?.salary?.frequency
                    )
                  : 0,
                "unitText": jobData?.job_profile?.job_overview
                  ? findSalaryFrequency(
                      jobData?.job_profile?.job_overview?.salary?.frequency
                    )
                  : "YEAR"
              }
            },
            "directApply": true,
            "url": `${
              process.env.REACT_APP_FRONTEND_URL
            }/${translate.getCurrentLocale()}/jobs/${jobId}`,
            ...(jobData?.job_profile?.job_overview?.remote_possible ===
            JOB_REMOTE_WORK_TYPE.FULLY_REMOTE
              ? {
                  jobLocationType: "TELECOMMUTE"
                }
              : {})
          })}
        </script>
      </Helmet>
      <Stack direction="column" gap={1.5}>
        <Typography variant="h3" mb={4} mt={6}>
          {intl.get("t_search_page_title")}
        </Typography>
        <Box display={{ xs: jobId ? "none" : "block", md: "block" }} pl={1}>
          <SearchHeader resetListScroll={resetListScroll} />
        </Box>
        <Stack
          display={{ xs: jobId ? "none" : "flex", md: "flex" }}
          direction="row"
          pl={1}
          justifyContent="space-between"
          alignItems="center">
          {isJobsLoading ? (
            <SkeletonTypography width="medium" />
          ) : (
            <Typography>
              {intl.get("t_job_search_result_count", { count: jobsCount })}
            </Typography>
          )}
          <Box noValidate component="form">
            <NonTypeableSelect
              options={JOB_SEARCH_SORT_ORDER_OPTIONS}
              name="sort"
              startAdornment={intl.get("t_job_search_sort_by")}
              placeholder={intl.get("t_job_search_sort_active")}
              clearable={false}
              control={control}
              setValue={setValue}
            />
          </Box>
        </Stack>
        <Box display={{ xs: jobId ? "block" : "none", md: "none" }}>
          <Button
            color="primary"
            handleClick={() => navigateJobIdURL("")}
            startAdornment={<NavigateBeforeIcon />}
            variant="text">
            {intl.get("t_job_search_back_to_search_results")}
          </Button>
        </Box>
        <Grid container>
          <Grid
            item
            xs={12}
            md={4}
            pr={{ xs: 1, md: 2 }}
            pl={1}
            display={{ xs: jobId ? "none" : "block", md: "block" }}
            overflow={{ md: "auto" }}
            ref={searchListRef}
            maxHeight={{ md: "calc(100vh - 8rem)" }}>
            <Stack direction="column" gap={1.5}>
              {isJobsLoading ? (
                [...Array(5)].map((_, index) => <SkeletonJobCard key={index} />)
              ) : (
                <>
                  {/* FIXME: Same, a typesense interface needs to be prepared */}
                  {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                  {jobs.map((singleJob: any) => {
                    const statusTags: Array<
                      typeof JOB_STATUS_TAGS[keyof typeof JOB_STATUS_TAGS]
                    > = [];
                    if (singleJob.unix_updated_at) {
                      const updatedAt = new Date(
                        singleJob.unix_updated_at * 1000
                      );

                      if (
                        getDaysAgo(updatedAt) <= JOB_NEW_TAG_LAST_UPDATED_DAY
                      ) {
                        statusTags.push(JOB_STATUS_TAGS.NEW);
                      }
                    }
                    if (
                      singleJob.client_company_name_en ||
                      singleJob.client_company_name_ja
                    ) {
                      statusTags.push(JOB_STATUS_TAGS.AGENCY);
                    }
                    if (singleJob.unix_last_login_at) {
                      const lastLoginAt = new Date(
                        singleJob.unix_last_login_at * 1000
                      );
                      if (
                        getDaysAgo(lastLoginAt) <=
                        JOB_ACTIVE_TAG_COMPANY_LAST_LOGIN_DAY
                      ) {
                        statusTags.push(JOB_STATUS_TAGS.ACTIVE);
                      }
                    }
                    const companyName: MultiLingual<string> = {
                      en: singleJob.company_name_en,
                      ja: singleJob.company_name_ja
                    };
                    const jobTitle: MultiLingual<string> = {
                      en: singleJob.title_en,
                      ja: singleJob.title_ja
                    };
                    const jobDescription: MultiLingual<string> = {
                      en: singleJob.job_description_en,
                      ja: singleJob.job_description_ja
                    };

                    const categories = [];

                    if (singleJob?.minimum_experience) {
                      categories.push(
                        intl.get(
                          MIN_YEARS_OF_EXPERIENCE_T_LABELS[
                            singleJob.minimum_experience as keyof typeof MIN_YEARS_OF_EXPERIENCE_T_LABELS
                          ]
                        )
                      );
                    }
                    if (
                      singleJob?.remote_work ==
                      JOB_REMOTE_WORK_TYPE.FULLY_REMOTE
                    ) {
                      categories.push(
                        intl.get(
                          JOB_REMOTE_WORK_TYPE_T_LABELS[
                            singleJob.remote_work as keyof typeof JOB_REMOTE_WORK_TYPE_T_LABELS
                          ]
                        )
                      );
                    }

                    if (
                      parseInt(LANGUAGE_PROFICIENCY.BUSINESS) >=
                      parseInt(singleJob?.language_requirements_en)
                    ) {
                      categories.push(intl.get("t_job_card_en_tag"));
                    }

                    if (
                      singleJob?.visa_sponsorship ==
                      JOB_VISA_SPONSORSHIP_AVAILABLE.YES
                    ) {
                      categories.push(intl.get("t_job_visa_sponsorship"));
                    }

                    return (
                      <JobCard
                        data-testid="search_job_card"
                        isBookmarked={
                          canUserBookmark(userProfile, userId)
                            ? userProfile.value?.bookmarks?.includes(
                                singleJob.job_id
                              )
                            : false
                        }
                        categories={categories}
                        companyName={resolveMultiLingual(companyName)}
                        status={statusTags}
                        jobTitle={resolveMultiLingual(jobTitle)}
                        location={{
                          country: singleJob.location_country,
                          city: singleJob.location_city
                        }}
                        key={singleJob.job_id}
                        description={resolveMultiLingual(jobDescription)}
                        companyLogo={getFileURL(
                          FILES_LOCATION_COMPANY_LOGO,
                          singleJob.company_id,
                          singleJob.company_logo_extension,
                          new Date(singleJob.company_logo_updated_at * 1000)
                        )}
                        handleClick={() => navigateJobIdURL(singleJob.job_id)}
                        createdDate={new Date(singleJob.unix_updated_at * 1000)}
                        handleBookmarkChange={
                          canUserBookmark(userProfile, userId)
                            ? (isBookmarked) =>
                                handleJobBookmarkChange(
                                  singleJob.job_id,
                                  isBookmarked,
                                  userProfile,
                                  userId
                                )
                            : undefined
                        }
                        isSelected={singleJob.job_id === jobId}
                      />
                    );
                  })}
                  {jobs.length > 0 ? (
                    <Stack direction="row" mb={1} justifyContent="center">
                      <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={(_, value) => {
                          searchParams.set("page", value.toString());
                          setSearchParams(searchParams);
                          navigateJobIdURL("");
                          resetListScroll();
                        }}
                      />
                    </Stack>
                  ) : (
                    false
                  )}
                </>
              )}
            </Stack>
          </Grid>
          <StyledJobSearchResultSelectedJobContainer item xs={12} md={8}>
            {isJobsLoading || isJobDataLoading ? (
              <SkeletonJobDetailCard />
            ) : jobs.length > 0 ? (
              jobData ? (
                <JobDetailCard
                  jobId={jobId}
                  updatedDate={
                    new Date(jobData.job_profile.updated_at.seconds * 1000)
                  }
                  location={jobData.job_profile.job_overview?.location}
                  jobStatuses={getJobStatusTags(jobData)}
                  isBookmark={
                    canUserBookmark(userProfile, userId)
                      ? userProfile.value?.bookmarks?.includes(jobData.job_id)
                      : false
                  }
                  experienceRequirement={
                    jobData?.job_profile.job_overview?.minimum_experience
                  }
                  handleBookmarkChange={
                    canUserBookmark(userProfile, userId)
                      ? (isBookmarked) =>
                          handleJobBookmarkChange(
                            jobId,
                            isBookmarked,
                            userProfile,
                            userId
                          )
                      : undefined
                  }
                  companyLogo={getFileURL(
                    FILES_LOCATION_COMPANY_LOGO,
                    jobData.job_profile.client_company_id ??
                      jobData.job_profile.company_id,
                    jobData.company_profile.logo?.extension,
                    jobData.company_profile?.logo?.uploaded_at
                  )}
                  companyName={resolveMultiLingual(
                    jobData.company_profile.name
                  )}
                  companyWebsite={jobData.company_profile.website_url}
                  agencyName={resolveMultiLingual(jobData.agency_profile?.name)}
                  isApplied={appliedJobIds?.includes(jobId)}
                  handleApply={() =>
                    handleApplyJob(jobId, jobData.job_profile.company_id)
                  }
                  jobApplyStatusCode={canUserApply(userProfile, user)}
                  jobTitle={resolveMultiLingual(jobData.job_profile.job_title)}
                  salary={jobData.job_profile.job_overview?.salary}
                  visaSponsorship={
                    jobData.job_profile.job_overview?.visa_sponsorship
                  }
                  jobContractType={
                    jobData.job_profile.job_overview?.contract_type
                  }
                  remoteAllowed={
                    jobData.job_profile.job_overview?.remote_possible
                  }
                  jaProficiency={
                    jobData.job_profile.language_requirement?.at(0)?.proficiency
                  }
                  enProficiency={
                    jobData.job_profile.language_requirement?.at(1)?.proficiency
                  }
                  jobDescription={resolveMultiLingual(
                    jobData.job_profile.job_description
                  )}
                  mustRequirements={resolveMultiLingual(
                    jobData.job_profile.must_requirements
                  )}
                  languageRequirement={jobData.job_profile.language_requirement}
                  requiredSkills={jobData.job_profile.skills}
                  otherDetails={resolveMultiLingual(
                    jobData.job_profile.additional_information
                  )}
                  cvFileRequirements={
                    jobData?.job_profile?.cv_file_requirements
                  }
                />
              ) : (
                <Stack my={10}>
                  <Typography variant="h2" textAlign="center">
                    {intl.get("t_job_not_found_error")}
                  </Typography>
                  <Typography variant="subtitle3" textAlign="center">
                    {intl.get("t_job_not_found_select_another")}
                  </Typography>
                </Stack>
              )
            ) : (
              false
            )}
          </StyledJobSearchResultSelectedJobContainer>
        </Grid>
      </Stack>
      {!isJobsLoading && jobs.length === 0 ? (
        <Stack my={5}>
          <Typography variant="h2" textAlign="center">
            {intl.get("t_job_search_no_results_found")}
          </Typography>
          <Typography variant="subtitle3" textAlign="center">
            {intl.get("t_job_search_try_different_query")}
          </Typography>
        </Stack>
      ) : (
        false
      )}
    </>
  );
};

export default Search;

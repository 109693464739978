import { useState } from "react";

import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  CheckCircleRounded as CheckCircleRoundedIcon,
  ErrorRounded as ErrorRoundedIcon
} from "@mui/icons-material";
import { Box, Grid, InputLabel, Link, Stack, Tooltip } from "@mui/material";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import * as yup from "yup";

import Button from "@components/Button";
import Paper from "@components/Paper";
import TextField from "@components/TextField";
import Typography from "@components/Typography";

import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import CompanyProfile from "@interfaces/database/CompanyProfile";

import {
  FIRESTORE_COLLECTIONS,
  FREE_TEXT_FIELD_MAX_LENGTH,
  USER_TYPE
} from "@utils/config";
import { auth, db } from "@utils/firebase";
import { prepareMultiLingual, resolveMultiLingual } from "@utils/multiLingual";
import Timestamp from "@utils/Timestamp";
import translate, { intl } from "@utils/translate";

interface AccountSettingsFormData {
  firstName?: string;
  lastName?: string;
}

const AccountSettings = () => {
  const [user] = useAuthState(auth);
  const userData = useUserProfile();
  const basicInformation = userData?.value?.summary?.basic_information;
  const toast = useToast();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { pathname } = useLocation();
  const currentLocale = translate.getCurrentLocale();

  const hasEmailAndPasswordProvider = user?.providerData.some(
    (provider) => provider.providerId === "password"
  );

  const handleUpdateSettingsSuccess = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_resumes_toast_success_update"), "success");
  };

  const handleUpdateSettingsFail = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const schema = yup.object({
    firstName: yup
      .string()
      .trim()
      .max(
        FREE_TEXT_FIELD_MAX_LENGTH,
        intl.get("t_error_max_limit", {
          field: intl.get("t_general_first_name"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        })
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_first_name")
        })
      ),
    lastName: yup
      .string()
      .trim()
      .max(
        FREE_TEXT_FIELD_MAX_LENGTH,
        intl.get("t_error_max_limit", {
          field: intl.get("t_general_last_name"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        })
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_last_name")
        })
      )
  });

  const initialValues = {
    firstName: resolveMultiLingual(basicInformation?.first_name) ?? "",
    lastName: resolveMultiLingual(basicInformation?.last_name) ?? "",
    email: basicInformation?.email ?? user?.email,
    password: hasEmailAndPasswordProvider ? "••••••••" : ""
  };

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control } = methods;

  const handleFormSubmit = async (formData: AccountSettingsFormData) => {
    setIsDisabled(true);
    const { firstName, lastName } = formData ?? {};

    try {
      if (userData.setValue && userData.value) {
        if (!userData.value?.summary) {
          userData.value.summary = {
            basic_information: {
              email: user?.email + ""
            }
          };
        }

        const multilingualFirstName = prepareMultiLingual(
          firstName ?? "",
          currentLocale,
          userData.value.summary.basic_information?.first_name
        );

        const multilingualLastName = prepareMultiLingual(
          lastName ?? "",
          currentLocale,
          userData.value.summary.basic_information?.last_name
        );

        await userData.setValue(
          {
            ...userData.value,
            summary: {
              ...userData.value?.summary,
              basic_information: {
                ...userData.value?.summary?.basic_information,
                first_name: multilingualFirstName,
                last_name: multilingualLastName,
                email: user?.email + ""
              }
            },
            updated_at: Timestamp.now()
          },
          handleUpdateSettingsSuccess,
          handleUpdateSettingsFail
        );

        // Update company manager records
        if (
          userData?.value.user_type === USER_TYPE.COMPANY_MANAGER &&
          userData?.value.company_id
        ) {
          const companyDocRef = doc(
            db,
            FIRESTORE_COLLECTIONS.COMPANIES,
            userData?.value.company_id
          );
          const companyDocSnap = await getDoc(companyDocRef);

          if (companyDocSnap.exists()) {
            const companyData = companyDocSnap.data() as CompanyProfile;
            const updatedManagers = { ...companyData.managers };
            const userId = user?.uid;
            const userEmail = user?.email;

            // Find the manager by user id/email and update first name and last name
            if (userId && userEmail) {
              if (updatedManagers[userId]) {
                updatedManagers[userId].first_name = multilingualFirstName;
                updatedManagers[userId].last_name = multilingualLastName;
              } else if (updatedManagers[userEmail]) {
                updatedManagers[userEmail].first_name = multilingualFirstName;
                updatedManagers[userEmail].last_name = multilingualLastName;
              }
            }

            await updateDoc(companyDocRef, { managers: updatedManagers });
          }
        }
      }
    } catch (error) {
      handleUpdateSettingsFail();
    }
  };

  return (
    <Paper>
      <Typography variant="h3">
        {intl.get("t_account_setting_title")}
      </Typography>

      <Box
        noValidate
        component="form"
        mt={3}
        onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container columnSpacing={3}>
          <Grid item container columnSpacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                data-testid="account_setting_last_name_input"
                disabled={isDisabled}
                name="lastName"
                label={intl.get("t_general_last_name")}
                placeholder={intl.get("t_general_last_name")}
                control={control}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                data-testid="account_setting_first_name_input"
                disabled={isDisabled}
                name="firstName"
                label={intl.get("t_general_first_name")}
                placeholder={intl.get("t_general_first_name")}
                control={control}
                required
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            mb={2}>
            <Button
              data-testid="account_setting_save_button"
              type="submit"
              loading={isDisabled}>
              {intl.get("t_general_save")}
            </Button>
          </Grid>

          <Grid item xs={12}>
            <InputLabel>{intl.get("t_account_setting_form_email")}</InputLabel>
            <TextField
              data-testid="account_setting_email_input"
              name="email"
              control={control}
              disabled
            />
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              mb={2.25}>
              {user?.emailVerified ? (
                <Stack
                  data-testid="account_setting_email_verified_label"
                  direction="row"
                  color="success.main"
                  alignItems="center"
                  spacing={1}>
                  <CheckCircleRoundedIcon />
                  <Typography variant="subtitle4">
                    {intl.get("t_account_setting_email_verified")}
                  </Typography>
                </Stack>
              ) : (
                <Stack
                  data-testid="account_setting_email_not_verified_label"
                  direction="row"
                  color="warning.main"
                  alignItems="center"
                  spacing={1}>
                  <ErrorRoundedIcon />
                  <Typography variant="subtitle4">
                    {intl.get("t_account_setting_email_not_verified")}
                  </Typography>
                </Stack>
              )}

              <Typography variant="subtitle4" color="primary.main">
                <Link
                  data-testid="account_setting_change_email_link"
                  underline="none"
                  href="https://www.tokhimo.com/tokhimo-jobs-faq"
                  target="_blank">
                  {intl.get("t_account_setting_change_email")}
                </Link>
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <InputLabel disabled={!hasEmailAndPasswordProvider}>
              {intl.get("t_account_setting_form_password")}
            </InputLabel>
            <TextField name="password" control={control} disabled />

            <Stack direction="row-reverse">
              {hasEmailAndPasswordProvider ? (
                <Typography variant="subtitle4">
                  <Link
                    underline="none"
                    href={`/${translate.getCurrentLocale()}/change-password?continueUrl=${pathname}`}
                    target="_blank"
                    color="primary.main">
                    {intl.get("t_account_setting_change_password")}
                  </Link>
                </Typography>
              ) : (
                <Tooltip
                  arrow
                  placement="bottom"
                  title={
                    <Typography variant="body2">
                      {intl.get(
                        "t_general_login_sso_not_change_password_message"
                      )}
                    </Typography>
                  }>
                  {/* span tag is required, otherwise it will not show the tooltip */}
                  <span>
                    <Typography variant="subtitle4" sx={{ cursor: "pointer" }}>
                      <Link
                        data-testid="account_setting_change_password_link"
                        underline="none"
                        href={`/${translate.getCurrentLocale()}/change-password?continueUrl=${pathname}`}
                        target="_blank"
                        sx={{ pointerEvents: "none" }}
                        color="text.disabled">
                        {intl.get("t_account_setting_change_password")}
                      </Link>
                    </Typography>
                  </span>
                </Tooltip>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default AccountSettings;
